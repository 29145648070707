<template>
  <div class="cards-container">
    <div v-if="boleto_option"
      @click="mudaParaBoleto"
      :class="`${store.getters.paymentType == 'boleto' ? 'card selected' : 'card'} `"
    >
      <img src="../../../assets/img/opcoes/fatura.svg" />
      <span>{{`Boleto- R$ ${(valorBoleto / 100).toFixed(2).replace(".",",")}`}}</span>
    </div>
    <span v-if="boleto_option">OU</span>
    <div @click="mudaParaCartao" :class="`${store.getters.paymentType == 'cartao' ? 'card selected' : 'card'} `">
      <img src="../../../assets/img/opcoes/cartao-de-credito.svg" />
      <span>Cartão de Crédito</span>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
export default {
  name: "CardBannerPagamento",
  props: {
    boleto_option: Boolean,
    valorBoleto: Number || String
  },
  setup() {
    const isBoleto = ref(false);
    const store = useStore();

    const mudaParaBoleto = () => {
      store.commit("setPaymentType" , "boleto");
      isBoleto.value = true;
    };

    const mudaParaCartao = () => {
      store.commit("setPaymentType" , "cartao");
      isBoleto.value = false;
    }

    return {
      mudaParaBoleto,
      mudaParaCartao,
      store
    }
  }
};
</script>

<style scoped>
.cards-container{
  display: flex;
  justify-content: center;
  align-items: center;
}

.selected {
  border: solid 3px #c90c53 !important;
}


.card {
  border: solid 3px rgb(211, 211, 211);
  border-radius: 10px;
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: unset !important;
  cursor: pointer;
  padding: 1rem;
}

@media only screen and (max-width: 400px) {
  #cod-sec{
    margin-top: 30px;
  }

  .btn-cadastro{
    z-index: 999;
  }
}

.alert-card{
  font-size: 12px;
  font-family: "Poppins";
  font-weight: bold;
  word-break: break-word;
  padding: 10px 20px;
  border: solid 2px rgba(0, 0, 0, 0.801);
  border-radius: 5px;
  color: black;
  opacity: .6;
  background-color: rgba(0, 0, 0, 0.219);
}

.success-alert{
  border: solid 2px #34a007;
  color: #34a007;
  background-color: #35a00728;
}

.error-alert{
  border: solid 2px #c90c0c;
  color: #851313;
  background-color: #c90c0c2f;
}

.cards-container .card span {
  margin: 1rem 0 0 0;
}

span {
  font-size: 11px;
  font-family: "Poppins";
  font-weight: bold;
  margin: 0 1rem;
}

.card img {
  width: 40px;
}

.float-right {
  float: right;
}

.footer {
  margin-top: 10rem;
  width: 100%;

  position: absolute;
}

.w-40 {
  width: 60em;
}

.purple {
  color: #c90c53 !important;
}

</style>