<template>
    <Navbar type="compra" :background="true" :isMobile="isMobile" :path="path" />
    <div :class="`${isMobile ? 'content-container-mobile col-11 mx-auto' : 'content-container col-10 mx-auto'}`">
        <div :class="`${isMobile ? 'coluna-1-mobile' : 'coluna-1'}`">
            <h2 :class="`${isMobile ? 'title-mobile' : 'title'}`">
                {{dependentes.length > 1 ? 'Quem participará dos planos?': 'Quem participará do plano?'}}
            </h2>
            <p :class="`${isMobile ? 'text-mobile' : 'text'}`">
                Preencha os campos abaixo com os dados de cada participante. Cada participante deve 
                estar vinculado a um plano, para maiores informações entre em contato conosco atráves 
                do nosso chat.
            </p>
            <template v-for="(dependente, index) in dependentes" :key="index">
                <FormDependentes
                    v-if="store.getters.dependentes.length > 0"
                    :dependent="dependente" 
                    :index="index" 
                    :subscriber="subscriber" 
                />
            </template>
            <Button type="cadastro" text="Cadastrar" :click="registerDependents" />
        </div>

        <ShoppingCarDetails :shoppingCar="store.getters.shoppingCar" :isMobile="isMobile" />
    </div>
    <Footer type="compra" :isMobile="isMobile" />
</template>

<script>
import Navbar from "../components/Elements/Navbar.vue";
import FormDependentes from "../components/FinalizarCompra/Dependentes/FormDependentes.vue";
import Footer from "../components/Elements/Footer.vue";
import { inject, onMounted, ref } from "vue";
import { useRoute, useRouter } from 'vue-router';
import Button from '../components/Elements/Button.vue';
import ShoppingCarDetails from '../components/FinalizarCompra/Cadastro/ShoppingCarDetails.vue';
import { useStore } from 'vuex';
import { allPlansAreSelected, createEventosSite, decodeJwt, is18orMoreYearsOld, registerDependentsWithHolder, getCookie, deleteCookie, getDeviceType, getMobileOperatingSystem, setCookieWithDomain, updateShoppingCar } from '../services/util';
import { GET, POST, PUT } from '../services/api';
import { numberToCpf, toDate } from '../services/formater';
import { isBirthday, isEmail, isEmpty, isName } from '../services/validate';
import { cpf } from 'cpf-cnpj-validator';

export default {
    name: "Dependentes",
    components: {
        Navbar,
        FormDependentes,
        ShoppingCarDetails,
        Footer,
        Button,
    },
    created() {
        this.isMobile = window.innerWidth < 800 ? true : false;
        window.addEventListener("resize", () => {
            this.isMobile = window.innerWidth < 800 ? true : false;
        });
        window.scroll(0,0);
    },
    setup() {
        const route = useRoute();
        const store = useStore();
        const loader = inject("loading");
        const alert = inject("alert");
        const shoppingCar = inject("shoppingCar");
        const router = useRouter();

        const isMobile = ref(false);
        const path = route.path.split('/')[2];
        const dependentes = ref([]);
        const subscriber = ref({});
        const isFilled = ref(false);
        console.log(dependentes)
        const isValidAge = (birthdate, planId) => {
            const isvalidAge = isBirthday(birthdate)
            const isPlanoKidsSelected = allPlansAreSelected()
            console.log(isPlanoKidsSelected)
            if(isvalidAge){
                if(planId==19){
                    let year = parseInt(birthdate.split("/")[2]);
                    let month = parseInt(birthdate.split("/")[1]);
                    let day = parseInt(birthdate.split("/")[0]);
                    const Is18 = is18orMoreYearsOld(day, month, year)
                    console.log(Is18)
                    if(Is18){
                        return false
                    }
                    else{ 
                        return true
                    }
                } else{
                    return true
                }
            }
        };
        
        const registerDependents = () => {
            loader.open();
            let dependentes = store.getters.dependentes;
            let hasAdult = false;
            let allDependentsValidate = [];
            dependentes.map( (dependente, index) => {
                delete dependente.useInfoUser;
                allDependentsValidate.push(false);

                const emailValidate = isEmail(dependente.email);
                const cpfValidate = cpf.isValid(dependente.cpf);
                const birthValidate = isValidAge(dependente.birth_date, dependente.plan_id);
                const nameValidate = isName(dependente.name)
                const nome_maeValidate = isName(dependente.nome_mae)
                const validate = isEmpty(dependente);
                const plansSelectedValidate = allPlansAreSelected();
                
                if (validate && 
                    emailValidate && 
                    cpfValidate && 
                    birthValidate && 
                    nameValidate && 
                    nome_maeValidate && 
                    plansSelectedValidate
                )  {
                    let year = parseInt(dependente.birth_date.split("/")[2]);
                    let month = parseInt(dependente.birth_date.split("/")[1]);
                    let day = parseInt(dependente.birth_date.split("/")[0]);
                    if (is18orMoreYearsOld(day, month, year)) hasAdult = true;
                    allDependentsValidate[index] = true;
                } else {
                    if(!validate) alert.open("Atenção!", "Verifique se todos os campos foram preechidos corretamente.");
                    else if(!plansSelectedValidate) alert.open("Atenção!", `Os planos selecionados para os participantes não condizem com os mesmos planos do carrinho!`);
                    else if(!nameValidate) alert.open("Atenção!", `Participante ${index + 1} - ${store.getters.dependentes[index].name} - Nome não deve conter caracteres especiais ou números!`);
                    else if(!nome_maeValidate) alert.open("Atenção!", `Participante ${index + 1} - ${store.getters.dependentes[index].name} - Nome da mãe não deve conter caracteres especiais ou números!`);
                    else if(!cpfValidate) alert.open("Atenção!", `Participante ${index + 1} - ${store.getters.dependentes[index].name} - CPF inválido!`);
                    else if(!emailValidate) alert.open("Atenção!", `Participante ${index + 1} - ${store.getters.dependentes[index].name} - Email inválido!`);
                    else if(!birthValidate) alert.open("Atenção!", `Participante ${index + 1} - ${store.getters.dependentes[index].name} - Data de aniversário inválida! O participante deve ter menos de 18 anos para participar de um plano kids.`);
                    Object.keys(dependente).map( (prop, ind) => {
                        if (prop != "cns") {
                            (dependente[prop] == "" || dependente[prop] == undefined) 
                                ? store.commit("setErrorsDependentes", {key: ind , index, value: true})
                                : store.commit("setErrorsDependentes", {key: ind , index, value: false})
                        }
                    })
                    window.scrollTo(0, 0);
                }
            });

            if (allDependentsValidate.every(item => item == true)) {
                dependentes.map( (dependente, index) => {
                    dependente.cpf = dependente.cpf ? dependente.cpf.replace(/\D/g, "") : "";
                    dependente.cns = dependente.cns ? dependente.cns.replace(/\D/g, "") : "";

                    Object.keys(dependente).map( (prop, ind) => {
                        if (prop != "cns") {
                            (dependente[prop] == "" || dependente[prop] == undefined) 
                                ? store.commit("setErrorsDependentes", {key: ind , index, value: true})
                                : store.commit("setErrorsDependentes", {key: ind , index, value: false})
                        }
                    })
                });
                store.commit("setDependentes", dependentes);
                hasAdult
                    ? registerDependentsWithHolder(false)
                    : store.commit("setModalTitular", {display: true});
            }
            loader.close();
        };

        onMounted( async () => {
            loader.open();
            await updateShoppingCar();
            let plans = [];
            let qtdDependentes = 0;
            let qtdMembros = 0;
            store.getters.shoppingCar.plans.map(plan => {
                plan.qtd_membros = plan.qtd * plan.qtd_membros
                qtdMembros += plan.qtd_membros 
                plans.push({ id: plan.id, qtd: plan.qtd, qtd_membros:plan.qtd_membros });
            });
            qtdDependentes=qtdMembros
            const domain = process.env.NODE_ENV === 'production' ? ".comprodonto.com.br" : window.location.hostname;
            deleteCookie("lead_id_cookie", "/finalizar-compra", domain);
            let token = JSON.parse(localStorage.getItem("token_comprodonto"));
            let decoded = token ? decodeJwt(token) : ""

            try{
                const response = await POST("verificaandamentov3", { plans });
                if (response.rota != "dependentes" && response.rota != "/minha-area") {
                    if (response.rota === "pagamento") isFilled.value = true;
                    else router.push("/finalizar-compra/" + response.rota);
                } else if(response.rota === "/minha-area") {
                    router.push("/minha-area");
                }

            } catch (err) {
                shoppingCar.finish();
                if (err.rota == "/home") {
                    alert.open(
                        "Abrangência Restrita",
                        "Verificamos que você adicionou ao seu carrinho dois planos de diferentes localidades. Devido à abrangência isso não é possível! Escolha o plano que atenda sua região."
                    );
                    router.replace("/");
                } else if (err.rota == "/verifica-email") {
                    alert.open(
                        "Usuário não autenticado!",
                        "É necessário confirmar o email para prosseguir!"
                    );
                    router.replace('/verifica-email');
                } else {
                    alert.open(
                        "Erro ao verificar cadastro",
                        "Não foi possível identificar o motivo do erro. Tente novamente por favor"
                    );
                    router.replace("/");
                }
            }

            for (let index = 0; index < qtdDependentes; index++) {
                dependentes.value.push({
                    name: "",
                    email: "",
                    cpf: "",
                    birth_date: "",
                    rg: "",
                    cns: "",
                    nome_mae: "",
                    plan_id: "",
                    parentesco: "",
                    estado_civil: "",
                    genero: "",
                    useInfoUser: false,
                });
                store.commit("updateErrorsDependentes", [false, false, false, false, false, false, false, false, false]);
            }

            if (isFilled.value) {
                const perfil = await GET(`perfil/${decoded.sub}`);
                let dependentes_data = perfil.subscriptions[0].dependentes;
                for(let i=0; i < qtdDependentes; i++) {
                    dependentes.value[i].name = dependentes_data[i] ? dependentes_data[i].nome_dependente : "";
                    dependentes.value[i].email = dependentes_data[i] ? dependentes_data[i].email : "";
                    dependentes.value[i].cpf = dependentes_data[i] ? numberToCpf(dependentes_data[i].cpf) : "";
                    dependentes.value[i].birth_date = dependentes_data[i] ? toDate(dependentes_data[i].birth_date) : "";
                    dependentes.value[i].rg = dependentes_data[i] ? dependentes_data[i].rg : "";
                    dependentes.value[i].cns = dependentes_data[i] ? dependentes_data[i].cns : "";
                    dependentes.value[i].nome_mae = dependentes_data[i] ? dependentes_data[i].nome_mae : "";
                    dependentes.value[i].plan_id = dependentes_data[i] ? String(dependentes_data[i].plan_id) : "";
                    dependentes.value[i].parentesco = dependentes_data[i] ? dependentes_data[i].parentesco : "";
                    dependentes.value[i].estado_civil = dependentes_data[i] ? dependentes_data[i].estado_civil : "";
                    dependentes.value[i].genero = dependentes_data[i] ? dependentes_data[i].genero : "";
                    dependentes.value[i].naturalidade = dependentes_data[i] ? dependentes_data[i].naturalidade : "";
                }
            } else {
                store.getters.myDataAlreadyChecked_formDependentes && store.commit("setMyDataAlreadyChecked", false);
            }

            if (plans.length === 1) {
                dependentes.value[0].plan_id = String(plans[0].id);
            }
            store.commit("setDependentes", dependentes.value);

            if (token) {
                try{
                    subscriber.value = await GET(`infos_subscriber/${decoded.sub}`);
                    store.commit("setSubscriber", subscriber.value);
                } catch (err) {
                    alert.open("Erro!", "Desculpe, não foi possível buscar seu cadastro.");
                }

                try{
                    const titularRes = await GET(`retorna_titular_por_subscriber/${subscriber.value.id}`);
                    if (titularRes.name) {
                        const titular = {
                            name: titularRes.name,
                            cpf: titularRes.cpf,
                            email: titularRes.email,
                            birth_date: toDate(titularRes.birth_date),
                            rg: titularRes.rg,
                            nome_mae: titularRes.nome_mae,
                            parentesco: titularRes.parentesco,
                            estado_civil: titularRes.estado_civil,
                            genero: titularRes.genero,
                            cns: titularRes.cns,
                            id: titularRes.id,
                            nome_pai: titularRes.nome_pai,
                            subscribers_id: titularRes.subscribers_id,
                        };
                        store.commit("setTitular", titular);
                    }
                } catch (e) {
                    alert.open("Erro!", e.message);
                }

                try {
                    const user = await GET(`usuarios/${decoded.sub}`);
                    let isVerified = user.verified_at;
                    isVerified === null && router.replace("/verifica-email");

                    const objLead = {
                        nome: `${user.name} ${user.last_name}`,
                        email: user.email,
                        telefone: user.phones[0].number,
                        planos: plans,
                        device: getDeviceType(),
                        os: getMobileOperatingSystem()
                    }
                    
                    let id_lead = getCookie('lead_id_cookie');
                    try{
                        let leadsRes;
                        if (id_lead){
                            leadsRes = await PUT(`leads/${id_lead}`, {planos: plans, deleted_at: null})
                        } else {
                            leadsRes = await POST("leads", objLead);
                            id_lead = leadsRes.id;
                            const domain = process.env.NODE_ENV === 'production' ? ".comprodonto.com.br" : window.location.hostname;
                            setCookieWithDomain('lead_id_cookie', id_lead, 14, domain);
                        }
                    } catch (e) {
                        alert.open("Erro!", e.message);
                    }
                } catch (e) {
                    alert.open("Erro!", "Desculpe, não foi possível buscar seu cadastro.");
                }
            } else {
                localStorage.removeItem("token_comprodonto");
                alert.open(
                    "Atenção!",
                    "Parece que você não tem permissão para acessar essa página!"
                );
                router.replace("/");
                store.commit("setModalLogin", {display: true});
                createEventosSite('VISUALIZACAO', 'modal_login');
            }

            loader.close();
        });

        return { isMobile, path, store, dependentes, subscriber, registerDependents }
    }
}
</script>

<style scoped>
    .content-container-mobile{
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        padding: 199px 0 6rem 0;
    }
    .content-container{
        display: flex;
        justify-content: space-between;
        padding: 94px 0 6rem 0;
    }

    .coluna-1-mobile{
        margin-right: 0;
        max-width: 25.625rem;
    }
    .coluna-1{
        margin-right: 2.2rem;
        margin-top: 3.5rem;
    }

    .title-mobile{
        color: #c90c53;
        font-size: 1.1875rem;
        font-weight: 600;
        position: relative;
        margin-bottom: .9375rem;
    }
    .title{
        color: #c90c53;
        font-size: 1.1875rem;
        font-weight: 600;
        position: relative;
        margin-bottom: .9375rem;
    }
    .title::before{
        content: '';
        display: block;
        width: 1.3125rem;
        height: .125rem;
        background-color: #ff50c2;
        position: absolute;
        top: -2.125rem;
    }

    .text-mobile{
        color: #000000;
        font-size: .75rem;
        font-weight: 500;
        line-height: normal;
        margin-bottom: .625rem;
        text-align: justify;
    }
    .text{
        color: #000000;
        font-size: .75rem;
        font-weight: 500;
        line-height: normal;
        margin-bottom: .625rem;
    }

    .doc-container{
        max-width: 18.4375rem;
        margin-top: 1.75rem;
        height: auto;
        background-color: #ffffff;
        text-align: left;
    }

    .list{
        font-size: .875rem;
        font-weight: 400;
        line-height: 1.5rem;
        padding: 0;
        margin-top: .8rem;
        margin-left: 1.2rem;
    }

    .item-list{
        color: #747474;
        text-align: left;
        margin: 1rem 0;
        line-height: 1rem;
    }
    .item-list::marker{
        color: #000;
    }

    .item-list a{
        text-decoration: none;
        color: #747474;
    }

    .subtitle{
        color: #000000;
        font-size: 1.0625rem;
        font-weight: 600;
        margin-bottom: .9375rem;
        text-transform: uppercase;
    }

    @media screen and (min-width: 800px) {
        .title, .text {
            max-width: 24rem;
        }
    }
</style>