<template>
    <Navbar
      type="compra"
      :background="true"
      :isMobile="isMobile"
      :path="path"
    />
    <div :class="`${isMobile ? 'content-container-mobile col-11 mx-auto' : 'content-container col-10 mx-auto'}`">
      <div :class="`${isMobile ? 'coluna-1-mobile' : 'coluna-1'}`">
        <h3 class="titleForm">Pagamento</h3>
        <CardBannerPagamento :boleto_option="boleto_option" :valorBoleto="valor_total_boleto" />

        <template v-if="store.getters.paymentType === 'cartao'">
          <label class="container-meus-dados" v-if="user">
            <input v-model="titular" @change="setCPF" type="checkbox" />
            Sou o(a) titular do cartão
          </label>
        </template>

        <div class="inputContainer">
          <label>Cupom de desconto</label>
          <Input
            type="default"
            :className="`input-sidebar-minhaArea uppercase ${cupom_class}`"
            placeholder="Cupom"
            v-model="cupom"
            :blur="validaCupom"
          />
        </div>
        <div class="mb-5" v-if="tem_cupom">
          <div
            class="alert-card error-alert"
            v-if="!cupom_valido"
          >
            *Cupom inválido!
          </div>
          <div
            class="alert-card success-alert"
            v-else
          >
            {{`${msg_cupom} ${valor_cupom}!`}}
          </div>
        </div>

        <template v-if="store.getters.paymentType === 'cartao'">
          <FormPagamento :isMobile="isMobile" />
          <CreditCard />
          <div class="bandeira-card">
            <img src="../assets/img/pagamento/pagamento-03.png" alt="" />
          </div>
        </template>

        <Button type="cadastro" text="Efetuar Pagamento" :click="makePayment" />
      </div>
      <ShoppingCarDetails :shoppingCar="store.getters.shoppingCar" :isMobile="isMobile" />
    </div>
    <Footer type="compra" :isMobile="isMobile" />
</template>
<script>
import Navbar from "../components/Elements/Navbar";
import Button from "../components/Elements/Button";
import Input from "../components/Elements/Input";
import Footer from "../components/Elements/Footer";
import FormPagamento from "../components/FinalizarCompra/Pagamento/FormPagamento.vue";
import ShoppingCarDetails from "../components/FinalizarCompra/Cadastro/ShoppingCarDetails.vue";
import CardBannerPagamento from "../components/FinalizarCompra/Pagamento/CardBannerPagamento.vue";
import CreditCard from "../components/FinalizarCompra/Pagamento/CreditCard.vue";
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { inject, onMounted, ref } from 'vue';
import { GET, POST } from '../services/api';
import { numberToCpf } from '../services/formater';
import { cpf } from 'cpf-cnpj-validator';
import { isEmpty, isExpDate, isName } from '../services/validate';
import { getCardType, updateShoppingCar } from '../services/util';
import "../assets/js/Pagseguro";

export default {
  name: "Pagamento",
  components: {
    Navbar,
    Footer,
    FormPagamento,
    ShoppingCarDetails,
    Button,
    CardBannerPagamento,
    CreditCard,
    Input
  },
  created() {
    this.isMobile = window.innerWidth < 800 ? true : false;
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth < 800 ? true : false;
    });
    window.scroll(0,0);
  },
  setup(){
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const loader = inject("loading");
    const alert = inject("alert");
    const shoppingCar = inject("shoppingCar");

    const isMobile = ref(false); 
    const path = route.path.split('/')[2];
    const cupom = ref("");
    const tem_cupom = ref(false);
    const array_planos = ref([]);
    const msg_cupom = ref("");
    const valor_cupom = ref(0);
    const cupom_valido = ref("");
    const cupom_class = ref("");
    const user = ref({});
    const plans = ref([]);
    const boleto_option = ref(true);
    const valor_total_boleto = ref(0);
    const hash = ref("");
    const titular = ref(false);

    const validaCupom = async () => {
      if(cupom.value != ""){
        let obj = {
          cupons: [cupom.value],
          plans: array_planos.value
        }
      
        const response = await POST('validar_cupons', obj);
        if(response.cupons[0].valido) {
          if (response.cupons[0].model.vitalicio == 1) {
            msg_cupom.value = '*Cupom aplicado. O valor das mensalidades serão de R$';
            alert.open("Cupom Válido", "O cupom será aplicado nas mensalidades.");
          } else {
            msg_cupom.value = '*Cupom aplicado. O valor da primeira mensalidade será de R$';
            alert.open("Cupom Válido", "O cupom será aplicado na mensalidade do primeiro mês.")
          }
          valor_cupom.value = response.valor;
          cupom_valido.value = cupom.value;
          cupom_class.value = 'success-class'
        } else {
          cupom.value = '';
          alert.open("Cupom Inválido", response.cupons[0].mensagem ? response.cupons[0].mensagem : 'Cupom Inválido');
          cupom_valido.value = '',
          valor_cupom.value = 0;
          cupom_class.value = 'error-class';
        }
        tem_cupom.value = true;
      }
    };

    const setCPF = () => {
      let cpf = titular.value ? JSON.parse(localStorage.getItem("user")).cpf : "";
      store.commit("setCreditCard", {prop: 'cpf', value: numberToCpf(cpf)});
    };

    const makePayment = async () => {
      loader.open();
      if (store.getters.paymentType === "cartao") {
        const creditCard = store.getters.creditCard;

        const cpfValidate = cpf.isValid(creditCard.cpf);
        const cvvValidate = creditCard.cvv.length === 3 ? true : false;
        const numberValidate = creditCard.number.length === 19 ? true : false;
        const expDateValidate = isExpDate(creditCard.exp_date); 
        const holderNameValidate = isName(creditCard.holder_name);
        const validate = isEmpty(creditCard);

        if (cpfValidate && cvvValidate && numberValidate && expDateValidate && validate && holderNameValidate) {
          let info = {
            cupons: cupom_valido.value ? [cupom_valido.value]: null,
            billing_info: {
              credit_card: {
                holder_name: creditCard.holder_name,
                number: creditCard.number.replace(/\D/g, ""),
                cvv: creditCard.cvv,
                expiration_month: creditCard.exp_date.split("/")[0],
                expiration_year: creditCard.exp_date.split("/")[1],
                bandeira: getCardType(creditCard.number),
                cpf: creditCard.cpf.replace(/\D/g, ""),
              },
            },
            plans: array_planos.value,
            lead_id: JSON.parse(localStorage.getItem("id_lead"))
          };

          try {
            const response = await POST("assinatura/checkout", info);
            if (response.success) {
              shoppingCar.finish();
              router.replace("/finalizar-compra/finalizado/cartao/" + response.success.protocolo);
            }
          } catch (e) {
            alert.open("Verifique os dados!", e.message);
          }
        } else {
          if(!validate) alert.open("Atenção!", "Verifique se todos os campos foram preechidos corretamente.");
          else if(!holderNameValidate) alert.open("Atenção!", `Nome do titular não deve conter caracteres especiais ou números!`);
          else if(!cpfValidate) alert.open("Atenção!", `CPF do titular inválido!`);
          else if(!numberValidate) alert.open("Atenção!", `Número do cartão inválido!`);
          else if(!cvvValidate) alert.open("Atenção!", `Código de segurança inválido!`);
          else if(!expDateValidate) alert.open("Atenção!", `Data de expiração inválida!`);
          Object.keys(creditCard).map( (prop, index) => {
            if (prop != "bandeira") {
              (creditCard[prop] == "" || creditCard[prop] == undefined) 
                ? store.commit("setErrorsPagamento", {index, value: true})
                : store.commit("setErrorsPagamento", {index, value: false})
            }
          })
          window.scroll(0,0);
        }
      } else {
        try{
          const response = await POST(`assinatura/checkout/boleto`, {
            senderHash: hash.value,
            cupons: cupom_valido.value ? [cupom_valido.value]: [],
            plans: array_planos.value,
            lead_id: JSON.parse(localStorage.getItem("id_lead"))
          });
          shoppingCar.finish();
          window.open(response.success.boleto.transaction.paymentLink, "_blank");
          router.replace("/finalizar-compra/finalizado/boleto/" + response.success.protocolo);
        } catch (e) {
          alert.open("Erro", "Ocorreu um erro ao gerar seu boleto, tente novamente mais tarde");
        }
      }
      loader.close();
    }

    onMounted( async () => {
      loader.open();
      user.value = JSON.parse(localStorage.getItem("user"));
      await updateShoppingCar();
      store.getters.shoppingCar.plans.map(plan => plans.value.push({ id: plan.id, qtd: plan.qtd }));
      try {
        const response = await POST("verificaandamentov3", { plans: plans.value });
        if (response.rota != "pagamento" && response.rota != "/minha-area") 
          router.replace("/finalizar-compra/" + response.rota);
        else if(response.rota == "/minha-area") 
          router.replace("/minha-area");
      } catch (err) {
        shoppingCar.finish();
        if (err.rota == "/home") {
          alert.open(
            "Abrangência Restrita",
            "Verificamos que você adicionou ao seu carrinho dois planos de diferentes localidades. Devido à abrangência isso não é possível! Escolha o plano que atenda sua região."
          );
          router.replace("/");
        } else if (err.rota == "/verifica-email") {
          alert.open(
            "Usuário não autenticado!",
            "É necessário confirmar o email para prosseguir!"
          );
          router.replace('/verifica-email');
        } else {
          alert.open(
            "Erro ao verificar cadastro",
            "Não foi possível identificar o motivo do erro. Tente novamente por favor"
          );
          router.replace("/");
        }
      }
      
      store.getters.shoppingCar.plans.map(plan => {
        if (!plan.valor_boleto) boleto_option.value = false;
        for(let i=0; i < plan.qtd; i++) {
          array_planos.value.push(plan.id);
          valor_total_boleto.value += plan.valor_boleto;
        }
      });

      try{
        const token = await GET(`pagseguro/get_session_id/${store.getters.shoppingCar.plans[0].provider_id}`);
        if (token.length > 0) {
          window.PagSeguroDirectPayment.setSessionId(token);
          window.PagSeguroDirectPayment.onSenderHashReady(function (response) {
            if (response.status == "error") {
              return false;
            }
            hash.value = response.senderHash;
          });
        }
      } catch (e) {
        alert.open("Erro!", e.message);
      }
      
      loader.close();
    });


    return {
      store,
      route,
      isMobile,
      path,
      cupom,
      validaCupom,
      tem_cupom,
      cupom_valido,
      cupom_class,
      msg_cupom,
      valor_cupom,
      user,
      boleto_option,
      setCPF,
      titular,
      valor_total_boleto,
      makePayment
    }
  }
};
</script>

<style  scoped>
* {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}

.titleForm {
  color: #000000;
  font-family: "Poppins";
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 3.5rem;
}

.btn-cadastro {
  width: 100%;
  height: 55px;
  background-color: #c90c53;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-top: 40px;
}
.btn-cadastro img {
    margin-left: 48px;
}
.btn-cadastro button {
  background-color: transparent;
  border: none;
  color: #fff;
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-container-mobile{
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 150px 0 6rem 0;
}
.content-container{
  display: flex;
  justify-content: space-between;
  padding: 94px 0 6rem 0;
}

.coluna-1-mobile{
  margin-right: 0;
}
.coluna-1{
  margin-right: 2.2rem;
}

.bandeira-card {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

@media screen and (max-width: 800px) {
  .bandeira-card {
    display: flex;
    justify-content: center;
  }
}


#cadastro-dependente {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}

#cadastro-dependente .cadastro-dependente {
  margin-top: 93px;
}

section {
  display: block;
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
}
#cadastro-dependente .cadastro-dependente .container {
  padding-top: 58px;
  padding-bottom: 113px;
  display: flex;
}
.container {
  width: 1100px;
  margin: 0 auto;
}

#cadastro-dependente .cadastro-dependente .container .coluna-1 h3 {
  color: #000000;
  font-family: "Poppins";
  font-size: 17px;
  font-weight: 600 !important;
  margin-bottom: 15px;
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}
.selected {
  border: solid 3px #c90c53 !important;
}
.card {
  /*border: solid 3px rgb(211, 211, 211);*/
  border: solid 3px #c90c53 !important;
  border-radius: 10px;
  padding: 2rem;
  margin: 2rem;
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: unset !important;
}
.cards {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}

.cards .card span {
  margin-top: 2rem;
}

.cards span {
  font-size: 11px;
  font-family: "Poppins";
  font-weight: bold;
}

#cadastro-dependente
  .cadastro-dependente
  .container
  .coluna-1
  .container-cadastro {
  width: 345px;
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}
form {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}

form .form-group label {
  color: #4b4b4b;
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  position: absolute;
  top: -9px;
  left: 15px;
  background-color: #fff;
  padding-right: 15px;
  padding-left: 10px;
  display: inline-block;
}

form .form-group input {
  height: 42px;
  border: 1px solid #cecece;
  width: 100%;
  border-radius: 5px;
  padding-left: 15px;
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 700;
  color: #000;
  outline: none;
}
input {
  overflow: visible;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.form-group d-flex flex-row {
  position: relative;
  margin-bottom: 28px;
  flex-direction: row !important;
  display: flex !important;
}

.container-carrinho {
  width: 354px;
  height: 636px;
  margin-left: auto;
  border: 1px solid #edebeb;
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  position: relative;
}
.container-carrinho .title-carrinho {
  width: 100%;
  height: 86px;
  display: flex;
  align-items: center;
  padding-left: 38px;
}

.container-carrinho .container-itens-carrinho {
  height: 352px;
  padding-bottom: 36px;
  overflow-y: scroll;
}

.container-carrinho .sub-total#subtotal {
  margin-top: auto;
  height: 58px;
  padding-left: 38px;
  border-top: 1px solid #edebeb;
}
.container-carrinho .sub-total .container-text-sub-total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 70%;
}

.container-carrinho .sub-total .container-text-sub-total p {
  color: #000000;
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
}

.container-carrinho .sub-total .container-text-sub-total span {
  color: #c90c53;
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: normal;
}

.container-carrinho .valor-total {
  padding-left: 38px;
  height: 71px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 37px;
  background-color: #f8f7f7;
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}

.justify-content-between {
  justify-content: space-between !important;
}
.d-flex {
  display: flex !important;
}

.coluna-1 {
  width: 345px;
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}
.footer-cadastro .container .comproodonto p {
  color: #747474;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
}

.btn-cadastro {
  width: 100%;
  height: 55px;
  background-color: #c90c53;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-top: 40px;
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}

.inputContainer{
    position: relative;
    margin: 1.25rem 0;
  }

  .inputContainer label{
    position: absolute;
    top: -.5rem;
    left: 1rem;
    background-color: #fff;
    z-index: 10;
    padding: 0 .3rem;
    color: #4b4b4b;
    font-size: .75rem;
    font-weight: 500;
    line-height: .9675rem;
  }

  .inputContainer:hover > label{
    color: #ff96db;
  }

@media only screen and (max-width: 400px) {
  #cod-sec {
    margin-top: 30px;
  }

  .btn-cadastro {
    z-index: 999;
  }
}

.alert-card {
  font-size: 12px;
  font-family: "Poppins";
  font-weight: bold;
  word-break: break-word;
  padding: 10px 20px;
  border: solid 2px rgba(0, 0, 0, 0.801);
  border-radius: 5px;
  color: black;
  opacity: 0.6;
  background-color: rgba(0, 0, 0, 0.219);
  user-select: none;
}

.success-alert {
  border: solid 2px #34a007;
  color: #34a007;
  background-color: #35a00728;
}

.error-alert {
  border: solid 2px #c90c0c;
  color: #851313;
  background-color: #c90c0c2f;
}

.cards span {
  font-size: 11px;
  font-family: "Poppins";
  font-weight: bold;
}

.card img {
  width: 40px;
}

.cards .card span {
  margin-top: 2rem;
}

.float-right {
  float: right;
}

.footer {
  margin-top: 10rem;
  width: 100%;

  position: absolute;
}

.w-40 {
  width: 60em;
}

.purple {
  color: #c90c53 !important;
}

.selected {
  border: solid 3px #c90c53 !important;
}

.container-meus-dados{
  display: flex;
  align-items: center;
  width: 15.875rem;
  height: 2.4375rem;
  border-radius: .25rem;
  background-color: rgba(237, 235, 235, 0.6);
  padding: .9375rem;
  margin-bottom: 2.1875rem;
  margin-top: 1rem;
  color: #c90c53;
  font-size: .75rem;
  font-weight: 600;
  letter-spacing: -0.24px;
  cursor: pointer;
}

.container-meus-dados input{
  margin-right: 1rem;
}

</style>