<template>
  <form class="form ">
    <div  class="form-group">
      <label>CPF do Proprietário do Cartão de Crédito</label>    
      <Input
        type="cpf"
        :className="store.getters.errors.pagamento[0] ? 'input-sidebar-minhaArea error' : 'input-sidebar-minhaArea'"
        placeholder="CPF"
        v-model="store.getters.creditCard.cpf"
      />
      <span class="msgErro" v-if="store.getters.errors.pagamento[0]">
        *Verifique se o campo está preechido com dados válidos!
      </span>
    </div>
    <div class="form-group">
      <label>Número do Cartão de Crédito</label> 
      <Input
        type="cardNumber"
        v-model="store.getters.creditCard.number"
        :className="store.getters.errors.pagamento[1] ? 'input-sidebar-minhaArea error' : 'input-sidebar-minhaArea'"
        placeholder="Nº Cartão"
      />
      <span class="msgErro" v-if="store.getters.errors.pagamento[1]">
        *Verifique se o campo está preechido com dados válidos!
      </span>
    </div>
    <div class="form-group">
      <label>Nome Impresso no Cartão de Crédito</label>
      <Input
        type="default"
        :className="store.getters.errors.pagamento[2] ? 'input-sidebar-minhaArea error' : 'input-sidebar-minhaArea'"
        v-model="store.getters.creditCard.holder_name"
        placeholder="Nome Titular"
      />
      <span class="msgErro" v-if="store.getters.errors.pagamento[2]">
        *Verifique se o campo está preechido com dados válidos!
      </span>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label>{{ isMobile ? 'Data de Exp.' : 'Data de Expiração' }}</label> 
          <Input 
            type="expDate"
            :className="store.getters.errors.pagamento[3] ? 'input-sidebar-minhaArea error' : 'input-sidebar-minhaArea'"
            v-model="store.getters.creditCard.exp_date"
            placeholder="MM/AA"
          />
          <span class="msgErro" v-if="store.getters.errors.pagamento[3]">
            *Campo obrigatório!
          </span>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Cód. Segurança</label>
          <input 
            inputmode="numeric"
            v-model="store.getters.creditCard.cvv" 
            placeholder="CVV"
            v-maska="'###'"
            :class="store.getters.errors.pagamento[4] ? 'error' : ''"
            @focus="() => store.commit('setFlipCard', true)"
            @blur="() => store.commit('setFlipCard', false)"
          />
          <span class="msgErro" v-if="store.getters.errors.pagamento[4]">
            *Campo obrigatório!
          </span>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import Input from "../../Elements/Input.vue";
import { useStore } from "vuex";

export default {
  name: "FormPagamento",
  props: {
    isMobile: Boolean,
  },
  components: { 
    Input 
  },
  setup() {
    const store = useStore();
        
    return {
      store
    }
  }
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  font: inherit;
  vertical-align: baseline;
}

form .form-group {
  position: relative;
  margin-bottom: 28px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.form-group label {
  color: #4b4b4b;
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  position: absolute;
  top: -9px;
  left: 15px;
  background-color: #fff;
  padding-right: 15px;
  padding-left: 10px;
}

form .form-group input {
    height: 42px;
    border: 1px solid #cecece;
    width: 100%;
    border-radius: 5px;
    padding-left: 15px;
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 700;
    color: #000;
    outline: none;
}

form .form-group input:hover {
  border: 1px solid #ff96db;
}
form .form-group:hover label {
  color: #ff96db;
}

.col-md-6 {
  flex: 0 0 50%;
  max-width: 50%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col-md-6 input {
  flex: 0 0 50%;
  height: 42px;
  border: 1px solid #cecece;
  width: 100%;
  max-width: 100%;
  border-radius: 0.3125rem;
  padding-right: 15px;
  padding-left: 15px;
  font-size: 0.75rem;
  font-weight: 700;
  color: #000;
  outline: none;
}

.card-item {
  max-width: 350px;
  position: relative;
  z-index: 2;
  width: 100%;
}

.card-item__side {
  border-radius: 15px;
  overflow: hidden;
  transform: perspective(2000px) rotateY(0deg) rotateX(0deg) rotate(0deg);
  transform-style: preserve-3d;
  transition: all 0.8s cubic-bezier(0.71, 0.03, 0.56, 0.85);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  height: 100%;
}

.card-item__focus {
  position: absolute;
  z-index: 3;
  border-radius: 5px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 0.35s cubic-bezier(0.71, 0.03, 0.56, 0.85);
  opacity: 0;
  pointer-events: none;
  overflow: hidden;
  border: 2px solid rgba(255, 255, 255, 0.65);
}

.btn-cadastro button,
.btn-cadastro a,
.btn-cadastro p,
.btn-cadastro span {
  background-color: transparent;
  border: none;
  color: #fff;
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* layout charles*/
.form-container {
  max-width: 100%;
}

.inputContainer {
  position: relative;
  margin: 1.25rem 0;
}

.inputContainer label {
  position: absolute;
  top: -0.5rem;
  left: 1rem;
  background-color: #fff;
  z-index: 10;
  padding: 0 0.3rem;
  color: #4b4b4b;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 0.9675rem;
}

.inputContainer:hover > label {
  color: #ff96db;
}

.iconLock {
  position: absolute;
  right: 1rem;
  top: 0.73rem;
  border-radius: 10px;
  color: #cb3f53;
  font-size: 1.4rem;
  width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;
}

.msgErro {
  color: #c90c53;
  font-size: .625rem;
  font-weight: bold;
  position: absolute;
  bottom: -15px;
  left: 0;
}

.error{
  border: solid 2px #ff96db !important;
}

@media screen and (min-width: 800px) {
  .form-container {
    max-width: 18.4375rem;
  }

  .iconLock {
    right: -1.8rem;
    top: 0;
    border-radius: 10px;
    background-color: #cb3f53;
    color: white;
    font-size: 1.1rem;
    width: 22px;
    height: 2.6rem;
  }
}
</style>