<template>
    <div v-if="isMobile" class="container-breadcrumb-mobile">
        <template v-for="item in items" :key="item.number">
            <router-link :to="item.path" :class="`${item.active ? 'item-mobile' : 'item-mobile disabled'}`">
                <span :class="`${item.active ? 'number-mobile' : 'number-mobile number-disabled'}`">
                    {{ item.number }}
                </span>
            </router-link>
            <span 
                v-if="!(item.number === '04')" 
                :class="`${item.active ? 'material-icons-outlined icon-mobile' : 'material-icons-outlined icon-mobile disabled'}`">
                    chevron_right
            </span>
        </template>
    </div>
    <div v-else class="container-breadcrumb">
        <template v-for="item in items" :key="item.number">
            <router-link :to="item.path" :class="`${item.active ? 'item' : 'item disabled'}`">
                <span :class="`${item.active ? 'number' : 'number number-disabled'}`">
                    {{ item.number }}
                </span> 
                {{item.text}}
            </router-link>
            <span 
                v-if="!(item.number === '04')" 
                :class="`${item.active ? 'material-icons-outlined icon' : 'material-icons-outlined icon disabled'}`">
                    chevron_right
            </span>
        </template>
    </div>
</template>

<script>

export default {
    name: "Breadcrumb",
    props: {
        path: String,
        isMobile: Boolean
    },
    data() {
        return{
            items: [
                {number: '01', text: 'Cadastro', path: '/finalizar-compra/cadastro', active: false},
                {number: '02', text: 'Participantes', path: '/finalizar-compra/dependentes', active: false},
                {number: '03', text: 'Pagamento', path: '/finalizar-compra/pagamento', active: false},
                // alterar parametros do path abaixo
                {number: '04', text: 'Concluído', path: '/finalizar-compra/finalizado/:tipo/:protocolo', active: false}
            ]
        }
    },
    created() {
        if (this.path === 'cadastro') this.items[0].active = true;
        else if (this.path === 'dependentes') {
            let i = 0;
            for (i; i <= 1; i++) {
                this.items[i].active = true;
            }
        } else if (this.path === 'pagamento') {
            let i = 0;
            for (i; i <= 2; i++) {
                this.items[i].active = true;
            }
        } else if (this.path === 'finalizado') {
            this.items.map(item => item.active = true);
        }
    }
}
</script>

<style scoped>
    .container-breadcrumb-mobile{
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
    .container-breadcrumb{
        width: 55vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    .item-mobile{
        display: flex;
        align-items: center;
        color: #c90c53;
        text-transform: uppercase;
        font-size: .6rem;
        font-weight: 700;
        cursor: pointer;
        text-decoration: none;
    }
    .item{
        display: flex;
        align-items: center;
        color: #c90c53;
        text-transform: uppercase;
        font-size: .6rem;
        font-weight: 700;
        cursor: pointer;
        text-decoration: none;
    }

    .number-mobile{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #c90c53;
        color: #fff;
        border-radius: 50%;
        width: 1.75rem;
        height: 1.75rem;
        font-size: .75rem;
        font-weight: 700;
        margin-right: .5rem;
    }
    .number{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #c90c53;
        color: #fff;
        border-radius: 50%;
        width: 1rem;
        height: 1rem;
        font-size: .55rem;
        font-weight: 700;
        margin-right: .5rem;
    }

    .icon-mobile{
        color: #c90c53;
        margin: 0 .3rem;
        font-size: 1rem;
    }
    .icon{
        color: #c90c53;
        margin: 0 .55rem;
        font-size: 1rem;
    }

    .number-disabled{
        color: #fff;
        background-color: #b7b7b7;
    }

    .disabled{
        color: #b7b7b7;
    }

    @media screen and (min-width: 975px){
        .container-breadcrumb{
            width: auto;
        }

        .item{
            font-size: .75rem;
        }

        .number{
            font-size: .75rem;
            width: 1.25rem;
            height: 1.25rem;
        }

        .icon{
            margin: 0 .75rem;
            font-size: 1.5rem;
        }
    }
</style>