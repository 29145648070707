<template>
    <section :class="`${isMobile ? 'sobre-mobile' : 'sobre'}`">
        <div :class="`${isMobile ? 'text-select-mobile col-11 mx-auto' : 'text-select col-10 mx-auto'}`">
          <div :class="`${isMobile ? 'sobre-text-mobile' : 'sobre-text'}`">
            <h1 :class="`${isMobile ? 'title-mobile' : 'title'}`">Olá nós somos a Comprodonto</h1>

            <div class="text-container">
              <p class="text-emphasis">A Comprodonto é um sistema exclusivo de venda de planos odontológicos da Cooperativa Uniodonto, o maior sistema deste segmento no Brasil.</p>
              <p class="text">Comprar pela Comprodonto significa ter as melhores informações para contratar com conforto e segurança, o melhor serviço para você, sua família e sua empresa.</p>
              <p class="text">Sem carência e com cobertura bem mais ampla em relação a outros planos, a Uniodonto lidera o segmento de cooperativas odontológicas pelo número de usuários, pelo número de profissionais cooperados e pela diversidade dos planos oferecidos. São pacotes com soluções customizadas de acordo com suas necessidades. Tudo isso a um clique, com atendimento personalizado e garantia nos serviços prestados. A Comprodonto é a Uniodonto mais perto de você!</p>
            </div>
          </div>

          <div v-if="!isMobile" class="container-img-sobre">
            <img class="img" src="../../assets/img/sobre/sobre-01.png" alt />
          </div>
        </div>
    </section>
</template>

<script>

export default {
    name: "BannerSobre",
    props: {
        isMobile: Boolean
    },
}
</script>

<style scoped>
    .sobre-mobile{
        position: relative;
        padding-top: 3rem;
        background-color: #b40d4d;
        padding-bottom: 10.2rem;
    }
    .sobre{
        position: relative;
        padding-top: 5.8125rem;
        background-image: linear-gradient(60deg,#a70f49,#d50453);
        padding-bottom: 10.2rem;
    }

    .container-img-sobre{
        position: absolute;
        top: 0;
        right: -4vw;
    }

    .text-select-mobile{
        padding: 0;
    }
    .text-select{
        padding: 0;
    }

    .sobre-text-mobile{
        position: relative;
        top: 6.25rem;
        width: 100%;
        z-index: 1;
    }
    .sobre-text{
        position: relative;
        top: 6.25rem;
        width: 42.75rem;
        z-index: 1;
    }

    .img{
        width: 95%;
    }

    .text-container{
        color: #fff;
    }

    .text-container .text:nth-child(2) {
        margin-top: 1.875rem;
        margin-bottom: 1.25rem;
    }

    .title-mobile{
        color: #fff;
        font-size: 1.6875rem;
        font-weight: 700;
        letter-spacing: -.1125rem;
        line-height: 2.4rem;
        margin-bottom: 2.5rem;
        width: 100%;
        padding-top: 1.5rem;
        position: relative;
        text-align: left;
    }
    .title{
        color: #fff;
        font-size: 3.75rem;
        font-weight: 700;
        letter-spacing: -.1125rem;
        line-height: 3.75rem;
        margin-bottom: 2.75rem;
        width: 29.375rem;
        padding-top: 3.1875rem;
        position: relative;
        text-align: left;
    }

    .title::before, .title-mobile::before{
        width: 2.5rem;
        height: 0.1875rem;
        background-color: #ff50c2;
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
    }

    .text-emphasis{
        font-size: 1.375rem;
        font-weight: 400;
        line-height: 1.75rem;
        text-align: left;
    }

    .text{
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5rem;
        text-align: left;
    }
</style>
