<template>
    <div v-if="isVisible" class="modal-overlay" @click.self="close">
        <div class="modal-container">
        <div class="modal-header">
            <h2>{{ title }}</h2>
            <button @click="close" class="close-button">&times;</button>
        </div>
        <div class="modal-body">
            <ul>
                <li v-for="(city, index) in cities" :key="index">{{ city.nome }}</li>
            </ul>
            <slot></slot>
        </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        title: {
        type: String,
        default: "Cidades da Região"
    },
        cities: {
        type: Array,
        default: () => []
    }
    },
    data() {
    return {
        isVisible: false,
    };
    },
    methods: {
    open() {
        console.log('ola', this.cities)
        this.isVisible = true;
    },
    close() {
        this.isVisible = false;
    },
    },
};
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center; /* Align items to the top */
    padding-top: 3rem; /* Adjust to fit beneath the header */
    z-index: 999;
}
.modal-container {
    background: white;
    padding: 1rem;
    border-radius: 5px;
    width: 80%;
    max-width: 500px;
    max-height: 80%; 
    overflow-y: auto; 
}
.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}
.modal-body {
    margin-top: 1rem;
}
.city-list {
    max-height: calc(100% - 3rem); 
    overflow-y: auto;
}

.city-list ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.city-list ul li {
    padding: 0.5rem;
    border-bottom: 1px solid #ccc;
}
</style>
