<template>
  <div v-if="store.getters.alert.display" @click="close" class="wrapper animate__animated animate__fadeIn">
    <div
      class="`animate__animated animate__fadeIn alertContainer"
      role="alert"
      id="alert"
      ref="alert"
    >
      <header class="alert-header">
        {{ store.getters.alert.title }}
      </header>
      <div class="alert-body">
        <p>{{ store.getters.alert.message }}</p>
      </div>
      <footer class="alert-footer">
        <button @click="close" class="btn">OK</button>
      </footer>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'

export default{
  name: "alertbox",
  setup() {
    const store = useStore()
    const close = () => {
      store.commit('alert', {display: false})
    }

    return { store, close }
  },
};
</script>

<style scoped>
  .wrapper{
    position: fixed;
    z-index: 99999999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.75);
    display: table;
    transition: opacity 0.3s ease;
  }

  .alertContainer{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    width: 90vw;
    min-height: 35vh;
    z-index: 9999999 !important;
    text-align: justify;
  }

  .message{
    margin: 0;
  }

  .icon{
    font-size: 24px;
    margin-right: 2.5rem;
  }

  .icon-mobile{
    font-size: 20px;
    margin-right: 1rem;
  }

  .alert-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #C90C53;
    color: #fff;
    font-size: 1.75rem;
    font-weight: 600;
    padding: .7rem 1rem;
  }

  .alert-body{
    padding: 1rem;
    color: #50596c;
  }

  .alert-body p{
    margin: 0;
    line-height: 1rem;
    font-size: .98rem;
  }

  .alert-footer{
    padding: 0;
    position: absolute;
    bottom: 0;
    width: 100%;
    border: none;
  }

  .btn{
    color: #5cb85c;
    background: transparent;
    border: none;
    font-size: 1rem;
    line-height: 1.25rem;
    outline: none;
    padding: 1rem;
    text-align: center;
    user-select: none;
    font-weight: 700;
    margin: 0;
    position: absolute;
    bottom: .5rem;
    right: .5rem;
  }
  .btn:hover{
    color: #5cb85c;
  }

  @media screen and (min-width: 800px){
    .alertContainer{
      width: 70vw;
    }
  }
</style>