<template>
    <form class="form-container" v-if="store.getters.dependentes.length > 0">
        <h3 class="title">{{ `Participante ${index + 1} ${name ? `- ${name}` : ""}` }}</h3>
        <label v-if="store.getters.myDataAlreadyChecked_formDependentes && dependente.useInfoUser || !store.getters.myDataAlreadyChecked_formDependentes && !dependente.useInfoUser" class="container-meus-dados">
            <input
                @change="(event) => getUserInfo(event.target.checked)"
                type="checkbox"
            />
            Usar meus dados de cadastro (Responsável Financeiro)
        </label>
    
        <div class="inputContainer">
            <label>Nome</label>
            <Input
                type="default"
                :className="store.getters.errors.dependentes[index][0] ? 'input-sidebar-minhaArea error' : 'input-sidebar-minhaArea'"
                placeholder="Nome"
                v-model="store.getters.dependentes[index].name"
                :blur="setName"
            />
            <span class="msgErro" v-if="store.getters.errors.dependentes[index][0]">
                *Verifique se o campo está preechido com dados válidos!
            </span>
        </div>
        <div class="inputContainer">
            <label>Email</label>
            <Input
                type="default"
                :className="store.getters.errors.dependentes[index][1] ? 'input-sidebar-minhaArea error' : 'input-sidebar-minhaArea'"
                placeholder="Email"
                v-model="store.getters.dependentes[index].email"
            />
            <span class="msgErro" v-if="store.getters.errors.dependentes[index][1]">
                *Verifique se o campo está preechido com dados válidos!
            </span>
        </div>
        <div class="inputContainer">
            <label>CPF</label>
            <Input
                type="cpf"
                :className="store.getters.errors.dependentes[index][2] ? 'input-sidebar-minhaArea error' : 'input-sidebar-minhaArea'"
                placeholder="CPF"
                autocomplete="off"
                v-model="store.getters.dependentes[index].cpf"
            />
            <span class="msgErro" v-if="store.getters.errors.dependentes[index][2]">
                *Verifique se o campo está preechido com dados válidos!
            </span>
        </div>
        <div class="inputContainer">
            <label>Data de Nascimento</label>
            <Input
                type="birth"
                :className="store.getters.errors.dependentes[index][3] ? 'input-sidebar-minhaArea error' : 'input-sidebar-minhaArea'"
                placeholder="DD/MM/AAAA"
                v-model="store.getters.dependentes[index].birth_date"
            />
            <span class="msgErro" v-if="store.getters.errors.dependentes[index][3]">
                *Verifique se o campo está preechido com dados válidos!
            </span>
        </div>
        <div class="inputContainer">
            <label>RG</label>
            <Input
                type="rg"
                :className="store.getters.errors.dependentes[index][4] ? 'input-sidebar-minhaArea error' : 'input-sidebar-minhaArea'"
                placeholder="RG"
                v-model="store.getters.dependentes[index].rg"
            />
            <span class="msgErro" v-if="store.getters.errors.dependentes[index][4]">
                *Verifique se o campo está preechido com dados válidos!
            </span>
        </div>
        <div class="inputContainer">
            <label class="label-link">
                CNS 
                <span class="nao-obrigatorio">(Não obrigatório)</span>
                <a class="link" href="http://www.ans.gov.br/index.php/a-ans/sala-de-noticias-ans/consumidor/1819-cartao-nacional-de-saude-uma-realidade-para-todos-os-brasileiros" target="_blank">
                    <span class="material-icons-outlined iconHelp" title="O que é cns?">help_outline</span>
                </a>
            </label>
            <Input
                type="cns"
                :className="store.getters.errors.dependentes[index][5] ? 'input-sidebar-minhaArea error' : 'input-sidebar-minhaArea'"
                placeholder="CNS"
                v-model="store.getters.dependentes[index].cns"
            />
            <span class="msgErro" v-if="store.getters.errors.dependentes[index][5]">
                *Verifique se o campo está preechido com dados válidos!
            </span>
        </div>
        <div class="inputContainer">
            <label>Nome da Mãe</label>
            <Input
                type="default"
                :className="store.getters.errors.dependentes[index][6] ? 'input-sidebar-minhaArea error' : 'input-sidebar-minhaArea'"
                placeholder="Nome da Mãe"
                v-model="store.getters.dependentes[index].nome_mae"
            />
            <span class="msgErro" v-if="store.getters.errors.dependentes[index][6]">
                *Verifique se o campo está preechido com dados válidos!
            </span>
        </div>
        <div class="inputContainer">
            <label>Planos</label>
            <Input
                type="select"
                :className="store.getters.errors.dependentes[index][7] ? 'input-sidebar-minhaArea error' : 'input-sidebar-minhaArea'"
                placeholder="Selecione o plano"
                v-model="store.getters.dependentes[index].plan_id"
                :data="plans"
            />
            <span class="msgErro" v-if="store.getters.errors.dependentes[index][7]">
                *Verifique se o campo está preechido com dados válidos!
            </span>
        </div>
        <div class="inputContainer">
            <label>Parentesco</label>
            <!-- <Input
                type="select"
                :className="store.getters.errors.dependentes[index][8] ? 'input-sidebar-minhaArea error' : 'input-sidebar-minhaArea'"
                placeholder="Selecione o grau de parentesco"
                v-model="store.getters.dependentes[index].parentesco"
                :data="[
                    {label: 'Cônjuge', value: 'Cônjuge'},
                    {label: 'Convivente, sem eventual concorrência com o cônjuge, salvo por decisão judicial', value: 'Convivente, sem eventual concorrência com o cônjuge, salvo por decisão judicial'},
                    {label: 'Enteado, o menor sob a guarda por força de decisão judicial e o menor tutelado, que ficam equiparados aos filhos', value: 'Enteado, o menor sob a guarda por força de decisão judicial e o menor tutelado, que ficam equiparados aos filhos'},
                    {label: 'Filhos comprovadamente inválidos, independente de idade', value: 'Filhos comprovadamente inválidos, independente de idade'},
                    {label: 'Filhos solteiro, até 21 anos incompletos, ou até 24 anos incompletos, desde que cursando graduação em nível superior de ensino', value: 'Filhos solteiro, até 21 anos incompletos, ou até 24 anos incompletos, desde que cursando graduação em nível superior de ensino'},
                    {label: 'Irmãos menores de 18 anos', value: 'Irmãos menores de 18 anos'},
                    {label: 'Nenhum, sou responsável financeiro', value: 'Nenhum, sou responsável financeiro'},
                    {label: 'Netos menores de 18 anos e solteiros', value: 'Netos menores de 18 anos e solteiros'},
                    {label: 'Pais', value: 'Pais'},
                    {label: 'Sogros', value: 'Sogros'},
                ]"
            /> -->
            <Input
                type="select-parentesco"
                :className="store.getters.errors.dependentes[index][8] ? 'input-sidebar-minhaArea error' : 'input-sidebar-minhaArea'"
                placeholder="Selecione o grau de parentesco"
                v-model="store.getters.dependentes[index].parentesco"
            />
            <span class="msgErro" v-if="store.getters.errors.dependentes[index][8]">
                *Verifique se o campo está preechido com dados válidos!
            </span>
        </div>
        <div class="inputContainer">
            <label>Estado Civil</label>
            <Input
                type="select"
                :className="store.getters.errors.dependentes[index][9] ? 'input-sidebar-minhaArea error' : 'input-sidebar-minhaArea'"
                placeholder="Selecione o estado civil"
                v-model="store.getters.dependentes[index].estado_civil"
                :data="[
                    {label: 'Amasiado', value: 'Amasiado'},
                            {label: 'Casado', value: 'Casado'},
                            {label: 'Convivente', value: 'Convivente'},
                            {label: 'Desquitado', value: 'Desquitado'},
                            {label: 'Divorciado', value: 'Divorciado'},
                            {label: 'Separado', value: 'Separado'},
                            {label: 'Solteiro', value: 'Solteiro'},
                            {label: 'Viúvo', value: 'Viúvo'},
                            {label: 'Outros', value: 'Outros'},
                ]"
            />
            <span class="msgErro" v-if="store.getters.errors.dependentes[index][9]">
                *Verifique se o campo está preechido com dados válidos!
            </span>
        </div>
        <div class="inputContainer">
            <label>Gênero</label>
            <Input
                type="select"
                :className="store.getters.errors.dependentes[index][10] ? 'input-sidebar-minhaArea error' : 'input-sidebar-minhaArea'"
                placeholder="Selecione o gênero"
                v-model="store.getters.dependentes[index].genero"
                :data="[
                    {label: 'Feminino', value: 'Feminino'},
                    {label: 'Masculino', value: 'Masculino'},
                    {label: 'Outros', value: 'Outros'},
                ]"
            />
            <span class="msgErro" v-if="store.getters.errors.dependentes[index][10]">
                *Verifique se o campo está preechido com dados válidos!
            </span>
        </div>
    </form>
</template>

<script>
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex'
import Input from '../../Elements/Input.vue';
import { numberToCpf } from '../../../services/formater';

export default {
    name: "FormDependentes",
    props: {
        dependent: Object,
        index: Number || String,
        subscriber: Object
    },
    components: {
        Input
    },
    setup(props) {
        const store = useStore();
        const dependente = ref(props.dependent);
        const plans = ref([]);
        const name = ref("");
        const formatDate = (date)=>{
            if (date) {
                const [year, month, day] = date.split('-');
                return `${day}/${month}/${year}`;
            }
            return '';
        }
        const getUserInfo = (value) => {
            store.commit("setMyDataAlreadyChecked", value);
            dependente.value.useInfoUser = value;
            if (value) {
                dependente.value.name = `${props.subscriber.name} ${props.subscriber.last_name}`
                dependente.value.email = props.subscriber.email;
                dependente.value.cpf = numberToCpf(props.subscriber.cpf);
                dependente.value.birth_date = formatDate(props.subscriber.birth_date)
                dependente.value.parentesco = "Nenhum, sou responsável financeiro";
            } else {
                dependente.value.name = "";
                dependente.value.email = "";
                dependente.value.cpf = "";
                dependente.value.birth_date = "";
                dependente.value.plan_id = "";
                dependente.value.parentesco = "";
            }
            store.commit("updateDependente", {index: props.index, value: dependente.value});
            name.value = dependente.value.name;
        };

        const setName = () => name.value = store.getters.dependentes[props.index].name;

        onMounted(() => {
            store.getters.shoppingCar.plans.map(plan => plans.value.push({label: plan.name, value: String(plan.id)}));
            name.value = dependente.value.name;
        });

        return { store, getUserInfo, dependente, plans, name, setName  }
    }
}
</script>

<style scoped>
    .form-container{
        max-width: 100%;
    }

    .container-meus-dados{
        display: flex;
        align-items: center;
        width: 15.875rem;
        height: 2.4375rem;
        border-radius: .25rem;
        background-color: rgba(237, 235, 235, 0.6);
        padding: .9375rem;
        margin-bottom: 2.1875rem;
        color: #c90c53;
        font-size: .75rem;
        font-weight: 600;
        letter-spacing: -0.24px;
    }

    .container-meus-dados input{
        margin-right: 1rem;
    }

    .title{
        color: #000;
        font-size: 1.1875rem;
        font-weight: 600;
        margin-top: 3.5rem;
        margin-bottom: 1rem;
        position: relative;
    }

    .inputContainer{
        position: relative;
        margin: 1.25rem 0;
    }

    .inputContainer label{
        position: absolute;
        top: -.5rem;
        left: 1rem;
        background-color: #fff;
        z-index: 10;
        padding: 0 .3rem;
        color: #4b4b4b;
        font-size: .75rem;
        font-weight: 500;
        line-height: .9675rem;
    }

    .inputContainer:hover > label{
        color: #ff96db;
    }

    .iconLock{
        position: absolute;
        right: 1rem;
        top: .73rem;
        border-radius: 10px;
        color: #cb3f53;
        font-size: 1.4rem;
        width: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;
        cursor: pointer;
    }

    .label-link{
        display: flex;
        align-items: flex-start;
    }

    .iconHelp{
        font-size: 1.2rem;
        position: relative;
        top: -.1rem;
    }

    .nao-obrigatorio{
        color: rgba(75, 75, 75, 0.507);
        margin: 0 .25rem;
    }

    .msgErro {
        color: #c90c53;
        font-size: .625rem;
        font-weight: bold;
        position: absolute;
        bottom: -15px;
        left: 0;
    }

    @media screen and (min-width: 800px){
        .form-container{
            max-width: 24rem;
        }

        .iconLock{
            right: -1.8rem;
            top: 0;
            border-radius: 10px;
            background-color: #cb3f53;
            color: white;
            font-size: 1.1rem;
            width: 22px;
            height: 2.6rem;
        }
    }
</style>
