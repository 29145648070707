<template>
    <div 
        class="sidebar-container" 
        v-if="store.getters.sidebarDadosCartao.display" 
    >
        <div class="background animate__animated animate__fadeIn" @click="closeSidebar"></div>
        <div class="content-container animate__animated animate__fadeInRight">
            <div class="sidebar-top">
                <h1 class="title">Alterar dados do cartão</h1>
                <span @click="closeSidebar" class="material-icons-outlined iconClose">close</span>
            </div>
            <form :class="`${isMobile ? 'form-mobile' : 'form'}`">
                <div class="row">
                    <div class="inputContainer col-12">
                        <label>Nº do Cartão</label>
                        <Input
                            type="cardNumber"
                            placeholder="Número do cartão"
                            className="input-sidebar-minhaArea"
                            v-model="data.number"
                        />
                    </div>
                </div>
                
                <div class="row">
                    <div class="inputContainer col-12">
                        <label>Nome do Titular</label>
                        <Input
                            type="default"
                            placeholder="Nome do titular impresso no cartão"
                            className="input-sidebar-minhaArea"
                            v-model="data.holder_name"
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="inputContainer col-6">
                        <label>Validade</label>
                        <Input
                            type="expDate"
                            placeholder="MM/AA"
                            className="input-sidebar-minhaArea"
                            v-model="data.exp_date"
                        />
                    </div>
                    <div class="inputContainer col-6">
                        <label>{{isMobile ? 'CVV' : 'Código de Segurança'}}</label>
                        <Input
                            type="cvv"
                            placeholder="***"
                            className="input-sidebar-minhaArea"
                            v-model="data.cvv"
                        />
                    </div>
                </div>

                <Button type="sidebar-minhaArea" text="Salvar dados" :click="changeCreditCard" />
            </form>
        </div>
    </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import Input from '../../Elements/Input.vue';
import Button from '../../Elements/Button.vue';
import { inject, ref } from 'vue';
import { isEmpty, isExpDate, isName } from '../../../services/validate';
import { getCardType } from '../../../services/util';
import { POST } from '../../../services/api';
export default {
    name: "SidebarDadosTitular",
    props: {
        isMobile: Boolean
    },
    components: {
        Input,
        Button
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        const loader = inject("loading");
        const alert = inject("alert");
        const data = ref({
            number: "",
            holder_name: "",
            exp_date: "",
            cvv: "",
        });

        const closeSidebar = () => store.commit("showSidebarDadosCartao", false);
        
        const changeCreditCard = async () => {
            loader.open();
            const cvvValidate = data.value.cvv.length === 3 ? true : false;
            const numberValidate = data.value.number.length === 19 ? true : false;
            const expDateValidate = isExpDate(data.value.exp_date); 
            const holderNameValidate = isName(data.value.holder_name);
            const validate = isEmpty(data.value);

            if(validate && cvvValidate && expDateValidate && numberValidate && holderNameValidate) {
                let mes = data.value.exp_date.split("/")[0]
                let ano = `20${data.value.exp_date.split("/")[1]}`
                const creditcard = {
                    brand: getCardType(data.value.number),
                    cardNumber: data.value.number.replaceAll(' ', ''),
                    expirationDate: `${mes}/${ano}`,
                    holder: data.value.holder_name,
                    securityCode: data.value.cvv
                };

                try{
                    await POST(`atualizar_recorrencia/${store.getters.sidebarDadosCartao.id_subs}`, {creditcard: creditcard});
                    store.commit("setSidebarDadosCartao", {
                        display: false,
                        id_subs: store.getters.sidebarDadosCartao.id_subs,
                        creditCardRegistered: creditcard
                    });
                    data.value = {
                        number: "",
                        holder_name: "",
                        exp_date: "",
                        cvv: ""
                    };
                    alert.open('Sucesso', 'Dados do cartão alterados com sucesso!');
                } catch (e) {
                    alert.open('Erro', 'Não foi possível alterar os dados do cartão. Tente novamente mais tarde!');
                }
            } else {
                if(!validate) alert.open("Atenção!", "Verifique se todos os campos foram preechidos corretamente.")
                else if(!holderNameValidate) alert.open('Atenção!', 'Nome do titular não deve conter caracteres especiais ou números.')
                else if(!numberValidate) alert.open('Atenção!', 'Número do cartão inválido.')
                else if(!expDateValidate) alert.open('Atenção!', 'Data de expiração inválida.')
                else if(!cvvValidate) alert.open('Atenção!', 'Código de segurança inválido.')
            }
            loader.close();
        };

        return { closeSidebar, store, router, data, changeCreditCard }
    },
}
</script>

<style scoped>
    .sidebar-container{
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        width: 100%;
        height: 100vh;
    }

    .background{
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,.33);
        width: 100%;
        height: 100vh;
    }

    .content-container{
        position: fixed;
        top: 0;
        right: 0;
        background-color: #fff;
        width: 36rem;
        height: 100vh;
        padding: 2rem 4rem;
    }

    .sidebar-top{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .title{
        color: #c90c53;
        font-size: 1rem;
        font-weight: 500;
        line-height: .9375rem;
        text-transform: uppercase;
    }

    .iconClose{
        color: #C90C53;
        font-size: 2rem;
        user-select: none;
        cursor: pointer;
    }

    .inputContainer{
        position: relative;
        margin: 1.25rem 0;
    }

    .inputContainer label{
        position: absolute;
        top: -.5rem;
        left: 2rem;
        background-color: #fff;
        z-index: 10;
        padding: 0 .3rem;
        color: #4b4b4b;
        font-size: .75rem;
        font-weight: 500;
        line-height: .9675rem;
    }

    .inputContainer:hover > label{
        color: #ff96db;
    }

    .form-mobile{
        overflow-y: auto;
        height: 100vh;
        margin-top: 1rem;
        padding-bottom: 10rem;
        padding-right: .5rem;
    }

    @media screen and (max-width: 584px) {
        .content-container{
            width: 100%;
            padding: 2rem 1.5rem;
        }
    }
</style>