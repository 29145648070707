<template>
    <div :class="`${isMobile ? 'container-minhasfaturas-mobile' : 'container-minhasfaturas'}`">
        <div v-if="temFatura">
            <h1 v-if="!isMobile" class="title">Minhas Faturas</h1>
            <table :class="`${isMobile ? 'table-mobile' : 'table'}`">
                <tr :class="`${isMobile ? 'row-mobile' : 'row'}`">
                    <th :class="`${isMobile ? 'cell-header-mobile' : 'cell-header'}`">Data</th>
                    <th :class="`${isMobile ? 'cell-header-description-mobile' : 'cell-header-description'}`">Status</th>
                    <th :class="`${isMobile ? 'cell-header-mobile' : 'cell-header'}`">Valor</th>
                </tr>
                <tbody>
                    <tr :class="`${isMobile ? 'row-mobile' : 'row'}`" v-for="(transaction, index) in transactions" :key="index">
                        <td :class="`${isMobile ? 'cell-mobile' : 'cell'}`">{{ toDate(transaction.created_at) }}</td>
                        <td :class="`${isMobile ? 'cell-description-mobile' : 'cell-description'}`">{{transaction.status_description}}</td>
                        <td :class="`${isMobile ? 'cell-mobile' : 'cell'}`">{{ toMoney(parseInt(transaction.amount)/100) }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="empty-store" v-else>
            <span class="material-icons-outlined iconFile">description</span>
            <p>Você não possui nenhuma fatura.</p>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import { toDate, toMoney } from '../../../services/formater';

export default {
    name: "MinhasFaturas",
    props: {
        transactions: Array || null,
        isMobile: Boolean
    },
    created() {
        this.temFatura = (this.transactions && this.transactions.length > 0) ? true : false;
    },
    setup() {
        const temFatura = ref('');

        return { temFatura, toMoney, toDate }
    },
}
</script>

<style scoped>
    .container-minhasfaturas-mobile{
        width: 100%;
    }
    .container-minhasfaturas{
        padding: 10rem 0 0 6rem;
        width: calc(100% - 14.5rem);
    }

    .title{
        color: #c90c53;
        font-size: 1rem;
        font-weight: 700;
        text-transform: uppercase;
        margin: 0 0 2.1875rem 0;
        text-align: left;
    }

    .table-mobile{
        width: 100%;
        color: #212529;
        text-align: left;
        margin-top: 1.5rem;
        margin-bottom: 5rem;
    }
    .table{
        width: 100%;
        color: #212529;
        text-align: left;
        margin-bottom: 5rem;
    }

    .row-mobile{
        border-top: 1px solid #000;
        margin: 0;
        width: 100%;
        display: flex;
        font-size: .8125rem;
        min-height: 4.125rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .row{
        border-top: 1px solid #000;
        margin: 0;
        width: 100%;
        display: flex;
        font-size: .8125rem;
        height: 2.875rem;
        display: flex;
        align-items: center;
    }
    
    .cell-header{
        display: inline-block;
        position: relative;
        font-weight: 500;
        font-size: .75rem;
        width: 20%;
        padding-left: .75rem;
    }
    .cell-header-description{
        display: inline-block;
        position: relative;
        font-weight: 500;
        font-size: .75rem;
        width: 60%;
        padding-left: .75rem;
    }
    .cell-header-mobile{
        display: inline-block;
        position: relative;
        font-weight: 500;
        font-size: .75rem;
        width: 22.5%;
        padding: 0;
    }
    .cell-header-description-mobile{
        display: inline-block;
        position: relative;
        font-weight: 500;
        font-size: .75rem;
        width: 50%;
        padding: 0;
    }

    .cell-mobile{
        border: none;
        width: 22.5%;
    }
    .cell-description-mobile{
        border: none;
        width: 50%;
    }
    .cell{
        padding: .375rem 2rem .375rem .75rem;
        border: none;
        width: 20%;
    }
    .cell-description{
        padding: .375rem 2rem .375rem .75rem;
        border: none;
        width: 60%;
    }

    .empty-store{
        font-weight: bold;
        color: #c90c53;
        padding-top: 2rem;
        font-size: 1.5rem;
        user-select: none;
        text-align: center;
        margin-bottom: 2rem;
    }

    .iconFile{
        color: #c90c53;
        font-size: 12rem;
    }
</style>