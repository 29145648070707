<template>
  <div :class="`${isMobile ? 'col-10 p-0 m-auto' : 'col-12 p-0 m-auto'}`">
    <div :class="`verificaEmail ${!changeEmail ? 'height' : ''}`">
      <div class="container">
        <div class="formulario">
          <div class="container-logo">
            <img src="../assets/img/login/login-01.png" alt />
          </div>
          <div class="container-cadastro">
            <p class="texto">
              Para adiquirir o(s) plano(s), você precisa confirmar seu email
              para que seja possível enviar contratos, documentos e mantermos
              uma comunicação. Enviamos um email de confirmação para
              <span> {{ email_cadastrado }} </span>
              lembre-se de confirmar a caixa de spam.
              <span v-if="!changeEmail">
                <button
                  class="botao-login"
                  @click="(event) => reenviarLink(event.target.textContent)"
                >
                  Enviar link novamente
                </button>
              </span>
              <span>
                <button class="btn-changeEmail" @click="changeEmailHandler">
                  {{ textBtn }}
                </button>
              </span>
            </p>
          </div>

          <div v-if="changeEmail" class="container-cadastro">
            <p class="texto-reenvio">
              Informou o email errado? Você ainda pode corrigir, informando o
              email correto no campo abaixo:
            </p>
            <div class="form">
              <div class="form-group">
                <label>E-Mail</label>
                <input
                  type="text"
                  v-model="email_alterado"
                  id="email"
                />
              </div>
            </div>

            <button
              class="botao-login"
              @click="(event) => reenviarLink(event.target.textContent)"
            >
              Confirmar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { inject, onMounted, ref } from '@vue/runtime-core';
import { GET, PUT } from '../services/api';
import { createEventosSite, decodeJwt } from '../services/util';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
  name: "VerificaEmail",
  created() {
    this.isMobile = window.innerWidth < 800 ? true : false;
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth < 800 ? true : false;
    });
  },
  setup() {
    const loader = inject("loading");
    const alert = inject("alert");
    const store = useStore();
    const router = useRouter();

    const email_cadastrado = ref("");
    const email_alterado = ref("");
    const user = ref({});
    const textBtn = ref("Trocar email");
    const changeEmail = ref(false);
    const isMobile = ref(false);

    const reenviarLink = async (text) => {
      loader.open();
      let obj = { email: "" };
      obj.email = text.includes("link") ? email_cadastrado.value : email_alterado.value;
      if (obj.email) {
        try{
          await PUT(`enviar_confirmacao_email/${user.value.id}`, obj);
          alert.open(
            "Sucesso!",
            `Um email foi encaminhado para ${obj.email}. 
            Se não o encontrar na sua caixa de mensagem, verifique também a sua caixa de Spam!`
          );
          if (changeEmail.value) {
            changeEmail.value = !changeEmail.value;
            textBtn.value = changeEmail.value ? "Cancelar" : "Trocar email";
            email_alterado.value = "";
            email_cadastrado.value = obj.email;
          }
        } catch (e) {
          alert.open(
            "Erro!",
            "O email de verificação não pôde ser encaminhado. Tente novamente mais tarde!"
          );
        }
      } else {
        alert.open( "Erro!", "O email de verificação não pôde ser encaminhado.");
      }
      loader.close();
    };

    const changeEmailHandler = () => {
      changeEmail.value = !changeEmail.value;
      textBtn.value = changeEmail.value ? "Cancelar" : "Trocar email";
    };

    onMounted( async () => {
      loader.open();
      let token = JSON.parse(localStorage.getItem("token_comprodonto"));
      let emailNotVerified = JSON.parse(localStorage.getItem("email_notVerified_comprodonto"));
      if (token && emailNotVerified) {
        let decoded = decodeJwt(token);
        user.value = decoded.usr;
        email_cadastrado.value = emailNotVerified;
        try {
          await GET(`usuarios/${user.value.id}`);
        } catch (e) {
          if (e.message.includes("Token")) {
            alert.open(
              "Erro!",
              "Acesso expirado ou inválido! Faça login novamente para continuar."
            );
            localStorage.clear();
            router.replace("/");
            store.commit("setModalLogin", {display: true});
            createEventosSite('VISUALIZACAO', 'modal_login');
          } else {
            alert.open("Erro!", e.message);
          }
        }
      } else {
        if (!emailNotVerified) {
          alert.open("Erro!", "Email já verificado.");
          router.replace("/");
        } else { 
          alert.open(
            "Erro!",
            "Acesso expirado ou inválido! Faça login novamente para continuar."
          );
          localStorage.clear();
          router.replace("/");
          store.commit("setModalLogin", {display: true});
          createEventosSite('VISUALIZACAO', 'modal_login');
        }
      }
      loader.close();
    })

    return { 
      reenviarLink, 
      changeEmailHandler, 
      isMobile, 
      changeEmail, 
      textBtn, 
      email_cadastrado, 
      email_alterado 
    }
  }
};

</script>

<style scoped>
.botao-login {
  background-color: #00fff5;
  border: none;
  color: #c70851;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  width: 100%;
  height: 55px;
  margin-top: 1.5em;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: space-around;
}
.botao-login:hover {
  background-color: #c70851;
  color: #fff;
  border: 2px solid #fff;
  transition: 0.3s;
  transform: scale(1.07);
}
.btn-changeEmail {
  background-color: transparent;
  border: 2px solid #00fff5;
  color: #00fff5;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  width: 100%;
  height: 55px;
  margin-top: 1.5em;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.btn-changeEmail:hover{
  background-color: #00fff5;
  color: #c70851;
  transform: scale(1.07);
  transition: 0.3s;
}

.formulario {
  position: relative;
  top: -80px;
}

.verificaEmail {
  background-color: #c70851;
  width: 100%;
  padding-top: 151px;
}

.height {
  height: 100vh;
}

.verificaEmail .container .formulario .container-logo {
  margin-bottom: 60px;
}

.verificaEmail .container .formulario {
  width: 352px;
  margin-left: auto;
  margin-right: auto;
}

.formulario .form-group {
  position: relative;
  margin-bottom: 28px;
}

.verificaEmail .container .formulario .form-group label {
  background-color: #c70851;
  color: #ff96db;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
}

.formulario .form-group label {
  color: #4b4b4b;
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  position: absolute;
  top: -9px;
  left: 15px;
  background-color: #fff;
  padding-right: 15px;
  padding-left: 10px;
}

.verificaEmail .container .formulario .form-group input {
  background-color: rgb(232, 240, 254) !important;
  border: 1px solid #ff96db;
  color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
}

.btn-login {
  width: 100%;
  height: 55px;
  background-color: #00fff5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 5px;
  margin-top: 40px;
}

.formulario .form-group input {
  height: 42px;
  border: 1px solid #cecece;
  width: 100%;
  border-radius: 5px;
  padding-left: 15px;
  font-size: 12px;
  font-weight: 700;
  outline: none;
}

.verificaEmail .container .formulario a {
  color: #ffffff;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 700;
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}

.texto,
.texto-reenvio {
  font-size: 20px;
  color: #fff;
  text-align: justify;
  line-height: 1.1;
}

.texto-reenvio {
  margin: 1em 0 2em 0;
}

.texto span {
  display: block;
  margin: 0.5em 0;
  text-align: center;
  font-weight: 600;
}

@media (max-width: 480px) {
  .verificaEmail .container .formulario .container-logo img {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .verificaEmail .container .formulario {
    width: 100%;
  }
}
</style>
