<template>
    <div class="plan-container">
        <h2 class="title">
            {{ plan.name }}
            <span>{{ plan.qtd }}x</span
        ></h2>
        <p class="local">{{ `${plan.cidade} - ${plan.estado}` }}</p>
        <span class="planPrice">{{ toMoney(plan.price/100) }}<span class="mes">/mês</span></span>
    </div>
</template>

<script>
import { toMoney } from '../../../services/formater';

export default {
    name: "ShoppinCarDetailsCard",
    props: {
        plan: Object
    },
    setup() {

        return { toMoney }
    },
}
</script>

<style scoped>
    .plan-container{
        text-align: left;
        margin: 1.5rem 0;
    }

    .title{
        color: #000;
        font-size: 1.0625rem;
        font-weight: 600;
        margin-bottom: 0;
    }

    .title span{
        color: rgb(37, 37, 37);
        font-size: .9rem;
        font-weight: 600;
        margin-bottom: 0;
        margin-left: .5rem;
    }

    .local{
        color: #4d4d4d;
        font-size: .75rem;
        font-weight: 400;
        letter-spacing: -.24px;
        margin-top: .1875rem;
        margin-bottom: .4rem;
    }

    .planPrice{
        color: #c90c53;
        font-size: 1rem;
        font-weight: 600;
        display: flex;
        align-items: center;
    }

    .mes{
        color: #747474;
        font-size: .75rem;
        font-weight: 500;
        display: block;
        margin-left: .1875rem;
    }
</style>