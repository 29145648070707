import { createStore } from 'vuex'

const store = createStore({
   state:{
      name: "Vue",
      alert:{
        display: false,
        title: "",
        message: "",
      },
      modal:{
        display: false,
        title: "",
        message: "",
        confirmMessage: "",
        callbackOK: "",
        callbackCancel: ""
      },
      modalTermsAndPolitics:{
        display: false,
        content: ''
      },
      modalLogin:{
        display: false,
      },
      modalTitular:{
        display: false,
      },
      loading: false,
      sidebar: false,
      sidebarDadosCartao: {
        display: false,
        id_subs: '',
        creditCardRegistered: {
          brand: '',
          cardNumber: '',
          expirationDate: '',
          holder: '',
          securityCode: ''
        }
      },
      sidebarDadosTitular: {
        display: false,
        data: {}
      },
      sidebarSenha: false,
      shoppingCar: {
        display: false,
        plans: [],
        total: 0,
        subtotal: 0,
        adesao: 0
      },
      arquivos: [],
      subscriber: {},
      cidade: '',
      user_cadastro: {
        telefone: "",
        confirmaPassword: "",
        terms: false,
      },
      user: {
        phones: {
          number: "",
          area_code: "",
        },
        name: "",
        last_name: "",
        email: "",
        password: "",
        cpf: "",
        zip_code: "",
        city: "",
        district: "",
        street: "",
        street_number: "",
        state: "",
        country: "BRA",
        complement: "",
        birth_date: ""
      },
      titular: {
        name: "",
        cpf: "",
        email: "",
        birth_date: "",
        rg: "",
        nome_mae: "",
        parentesco: "",
        estado_civil: "",
        genero: ""
      },
      dependentes: [],
      errors: {
        cadastro: [false, false, false, false, false, false, false, false, false, false, false],
        dependentes: [],
        titular: [false, false, false, false, false, false, false, false, false],
        pagamento: [false, false, false, false, false],
      },
      sectionMinhaArea: 'planos',
      hasDetalhesPlano: false,
      creditCard: {
        cpf: '',
        number: '',
        holder_name: '',
        exp_date: '',
        cvv: '',
        bandeira: ''
      },
      paymentType: 'cartao',
      flipCard: false,
      myDataAlreadyChecked_formDependentes: false,
      myDataAlreadyChecked_modalTitular: false,
      textSidebar: localStorage.getItem("token_comprodonto") ? 'Minha área' : 'Login'
   },
   getters:{
    alert: (state) => state.alert,
    loading: (state) => state.loading,
    modal: (state) => state.modal,
    modalTermsAndPolitics:(state) => state.modalTermsAndPolitics,
    modalLogin:(state) => state.modalLogin,  
    modalTitular:(state) => state.modalTitular,  
    sidebar: (state) => state.sidebar,   
    sidebarDadosCartao: (state) => state.sidebarDadosCartao,   
    sidebarDadosTitular: (state) => state.sidebarDadosTitular,   
    sidebarSenha: (state) => state.sidebarSenha,   
    shoppingCar: (state) => state.shoppingCar,   
    arquivos: (state) => state.arquivos,
    cidade: (state) => state.cidade,
    subscriber: (state) => state.subscriber,
    user: (state) => state.user,
    titular: (state) => state.titular,
    user_cadastro: (state) => state.user_cadastro,
    dependentes: (state) => state.dependentes,
    sectionMinhaArea: (state) => state.sectionMinhaArea,
    hasDetalhesPlano: (state) => state.hasDetalhesPlano,
    creditCard: (state) => state.creditCard,
    flipCard: (state) => state.flipCard,
    myDataAlreadyChecked_formDependentes: (state) => state.myDataAlreadyChecked_formDependentes,
    myDataAlreadyChecked_modalTitular: (state) => state.myDataAlreadyChecked_modalTitular,
    errors: (state) => state.errors,
    textSidebar: (state) => state.textSidebar,
    paymentType: (state) => state.paymentType,
   },
   mutations: {
    loading:(state, value) => state.loading = value,
    alert:(state, value) => state.alert = value,
    modal:(state, value) => state.modal = value,
    setModalTermsAndPolitics:(state, value) => state.modalTermsAndPolitics = value,
    setModalLogin:(state, value) => state.modalLogin = value,
    setModalTitular:(state, value) => state.modalTitular = value,
    setShoppingCar: (state, value) => state.shoppingCar.plans = value,
    showShoppingCar: (state, value) => state.shoppingCar.display = value,
    updateShoppingCarValues: (state, value) => state.shoppingCar = value,
    setArquivos: (state, value) => state.arquivos = value,
    setCidade: (state, value) => state.cidade = value,
    setSubscriber: (state, value) => state.subscriber = value,
    setUser: (state, value) => state.user = value,
    setDependentes: (state, value) => state.dependentes = value,
    updateDependente: (state, value) => state.dependentes[value.index] = value.value,
    setTitular: (state, value) => state.titular = value,
    updateTitular: (state, value) => {
      state.titular.name = value.name;
      state.titular.email = value.email;
      state.titular.cpf = value.cpf;
    },
    setSidebar: (state, value) => state.sidebar = value,
    setSidebarDadosTitular: (state, value) => {
      let data = {};
      if (value.getCep) {
        data = value.data;
        data.street = value.getCep.logradouro;
        data.city = value.getCep.localidade;
        data.state = value.getCep.uf;
        data.district = value.getCep.bairro;
      } else {
        if (value.data.phones || value.data.subscriber) {
          data = {
            area_code: value.data.phones ? value.data.phones.area_code : "",
            number: value.data.phones ? value.data.phones.number : "",
            telefone: value.data.phones ? `(${value.data.phones.area_code}) ${value.data.phones.number}` : "",
            zip_code: value.data.subscriber.zip_code,
            street: value.data.subscriber.street,
            district: value.data.subscriber.district,
            state: value.data.subscriber.state,
            city: value.data.subscriber.city,
            street_number: String(value.data.subscriber.street_number),
            complement: value.data.subscriber.complement
          };
        } else {
          data = value.data;
          if (data.telefone) {
            data.area_code = data.telefone.split(")")[0].replace(/\D/g, "");
            data.number = data.telefone.split(")")[1].replace(/\D/g, "");
          }
        }
      }
      state.sidebarDadosTitular = {display: value.display, data};
    },
    showSidebarDadosCartao: (state, value) => state.sidebarDadosCartao.display = value,
    setSidebarDadosCartao: (state, value) => {
      if (value.creditCardRegistered.brand === 'Master') value.creditCardRegistered.brand = 'Mastercard'
      else if (value.creditCardRegistered.brand === 'visa') value.creditCardRegistered.brand = 'Visa'
      else if (value.creditCardRegistered.brand === 'amex') value.creditCardRegistered.brand = 'Amex'
      state.sidebarDadosCartao = value;
    },
    setSidebarSenha: (state, value) => state.sidebarSenha = value,
    setSectionMinhaArea: (state, value) => state.sectionMinhaArea = value,
    setDetalhesPlano: (state, value) => state.hasDetalhesPlano = value,
    setCreditCard: (state, value) => state.creditCard[value.prop] = value.value,
    setCreditCardRegistered: (state, value) => state.creditCardRegistered = value,
    setFlipCard: (state, value) => state.flipCard = value,
    setMyDataAlreadyChecked: (state, value) => state.myDataAlreadyChecked_formDependentes = value,
    setMyDataAlreadyCheckedModalTitular: (state, value) => state.myDataAlreadyChecked_modalTitular = value,
    setErrorsCadastro: (state, value) => state.errors.cadastro[value.index] = value.value,
    setErrorsTitular: (state, value) => state.errors.titular[value.index] = value.value,
    setErrorsDependentes: (state, value) => state.errors.dependentes[value.index][value.key] = value.value,
    updateErrorsDependentes: (state, value) => state.errors.dependentes.push(value),
    setErrorsPagamento: (state, value) => state.errors.pagamento[value.index] = value.value,
    setTextSidebar: (state, value) => state.textSidebar = value,
    setPaymentType: (state, value) => state.paymentType = value,
    setUserAddress: (state, value) => {
      if (value === ""){
        state.user.zip_code = "";
        state.user.street = "";
        state.user.street_number = "";
        state.user.district = "";
        state.user.state = "";
        state.user.city = "";
      } else if(value.zip_code) {
        state.user.zip_code = value.zip_code;
      } else {
        state.user.street = value.logradouro;
        state.user.district = value.bairro;
        state.user.city = value.localidade;
        state.user.state = value.uf;
      }
    },
   }
})

export default store