<template>
  <div :class="`${isMobile ? 'col-10 p-0 m-auto' : 'col-12 p-0 m-auto'}`">
    <loading v-model:active="isLoading" color="#C90C53"></loading>
    <div class="login">
      <div class="container">
        <div class="formulario">
          <div class="voltar" @click="$router.back()">
            <span class="material-icons-outlined">chevron_left</span> Voltar
          </div> 
          <div class="container-logo">
            <img src="../assets/img/login/login-01.png" alt />
          </div>
          <div class="container-cadastro">
            <p class="texto">
              Seu email foi confirmado com sucesso. Clique no botão abaixo e
              conclua a compra do seu plano!
            </p>
          </div>
          <div class="btn-login">
            <button @click="confirm">Concluir compra</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { GET } from '../services/api';
import { deleteCookie } from '../services/util';

export default {
  name: "ConfirmaEmail",
  created() {
    this.isMobile = window.innerWidth < 800 ? true : false;
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth < 800 ? true : false;
    });
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const loader = inject("loading");
    const alert = inject("alert");
    const shoppingCar = inject("shoppingCar");

    const isMobile = ref(false);

    const confirm = () => router.replace("/finalizar-compra/dependentes");

    onMounted( async () => {
      loader.open();
      let url = route.path.split("/")[2].toString();
      try{
        const response = await GET(`confirmar_email/${url}`);
        const domain = process.env.NODE_ENV === 'production' ? ".comprodonto.com.br" : window.location.hostname;
        deleteCookie("lead_id_cookie", "/confirmar_email", domain);
        localStorage.setItem("token_comprodonto", JSON.stringify(response.token));
        localStorage.setItem("planos", JSON.stringify(response.carrinho));
        shoppingCar.start();
        localStorage.removeItem("email_notVerified_comprodonto");
      } catch (e) {
        alert.open("Erro!", "Não foi possível confirmar seu email. Tente novamente mais tarde!");
        router.replace("/verifica-email");
      }
      loader.close();
    });

    return { confirm, isMobile }
  }
};

</script>

<style scoped>

.login{
  background-color: #c70851;;
  padding: 0;
  margin: 0;
  height: 100vh;
}
.voltar {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin-bottom: 50px; 
  display: flex;
  align-items: center;
}
.voltar:hover{
  color: #00fff5;
  font-weight: 500;
  transform: scale(1.07);
  transition: 0.3s;
}

a {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}
a:hover{
  color: #00fff5;
  transition: 0.3s;
}
.container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.btn-login button {
  
  background-color: #00fff5;
  border: none;
  color: #c70851;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  width: 100%;
  height: 55px;
  margin-top: 1.5em;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: space-around;
}
.btn-login button:hover {
  background-color: #c70851;
  color: #fff;
  border: 1px solid #fff;
  transform: scale(1.07);
  transition: 0.3s;
}


.login .container .formulario .container-logo {
  margin-bottom: 30px;
}

.login .container .formulario .container-cadastro {
  margin-bottom: 40px;
}

.login .container .formulario {
  width: 352px;
  margin-left: auto;
  margin-right: auto;
}

.formulario .form-group {
  position: relative;
  margin-bottom: 28px;
}

.login .container .formulario a {
  color: #ffffff;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 700;
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}

.texto,
.texto-reenvio {
  font-size: 20px;
  color: #fff;
  text-align: justify;
  line-height: 1.1;
}

.texto-reenvio {
  margin: 1em 0 2em 0;
}

@media (max-width: 480px) {
  .login .container .formulario .container-logo img {
    width: 100%;
  }

  .login .container .formulario {
    width: 100%;
  }
}
</style>
