import { createApp} from 'vue'
import App from './App.vue'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Maska from 'maska'
import router from './router'
import alert from './services/alert'
import loading from './services/loading'
import modal from './services/modal'
import shoppingCar from './services/shoppingCar'
import store from '../store/index'
import 'bootstrap/dist/css/bootstrap.css'
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import 'material-icons/iconfont/material-icons.css';
import 'animate.css';
import '@vueform/multiselect/themes/default.css';


AOS.init();

const app = createApp(App).use(router)


app
.use(Maska)
.use(router)
.use(store)
.use(VueLoading)
.provide('alert', alert)
.provide('loading', loading)
.provide('modal', modal)
.provide('shoppingCar', shoppingCar)
.mount("#app");
