<template>
  <section
    :class="`${isMobile ? 'banner-container-mobile' : 'banner-container'}`"
  >
    <div
      :class="`${
        isMobile
          ? 'text-select-mobile col-11 mx-auto'
          : 'text-select col-10 mx-auto'
      }`"
    >
      <h1 :class="`${isMobile ? 'title-mobile' : 'title'}`">
        Seu novo plano odontológico sem sair de casa!
      </h1>
      <h2 :class="`${isMobile ? 'subtitle-mobile' : 'subtitle'}`">
        <span class="material-icons iconCheck">check_circle</span>
        Exclusivo Operadoras Uniodonto
      </h2>

      <span class="city-span">Encontre sua cidade abaixo:</span>

      <div :class="`${isMobile ? 'select-cidade-mobile' : 'select-cidade'}`">
        <Multiselect
          :options="cidades"
          placeholder="Procure sua cidade"
          mode="single"
          valueProp="url"
          trackBy="nome"
          label="nome"
          :searchable="true"
          :disabled="isDisabled"
          :max="1"
          noOptionsText="Nenhuma cidade encontrada"
          noResultsText="Cidade não encontrada"
          :openDirection="direction"
          @change="(value) => redirect(value)"
          class="multiselect-visible"
        />
      </div>
    </div>

    <div class="filter-img"></div>
  </section>
</template>

<script>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ref } from "vue";
import Multiselect from "@vueform/multiselect";
import { GET } from "../../services/api"; 
import { inject, onMounted } from "vue"; 

export default {
  name: "BannerHome",
  props: {
    isMobile: Boolean,
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      isDisabled: false,
      direction: "top",
    };
  },
  created() {
    this.direction = window.scrollY > 100 ? "bottom" : "top";
    window.addEventListener("scroll", () => {
      this.direction = window.scrollY > 100 ? "bottom" : "top";
    });
  }, 
  setup() {
    const loader = inject("loading");
    const alert = inject("alert");
    const store = useStore();
    const router = useRouter();
    const cidades = ref([]);

    onMounted(async () => {
      loader.open();
      try {
        cidades.value = await GET("cidades");
      } catch (err) {
        alert.open(
          "Erro",
          "Desculpe, ocorreu um erro ao listar as cidades!",
         
        );
      }
      loader.close();
    });

    const redirect = (value) => {
      let city = "";
      cidades.value.map(cidade => {
        if (cidade.url === value) city = cidade.nome;
        return;
      });
      store.commit("setCidade", city);
      router.push("/planos/" + value);
    };


    return { redirect, cidades };
  },
  
};

</script>

<style scoped>
.banner-container-mobile {
  height: 100vh;
  position: relative;
  background-image: url("../../assets/img/home/home-05.jpg");
  background-position: top left;
  background-size: cover;
}
.banner-container {
  height: 100vh;
  position: relative;
  background-image: url("../../assets/img/home/home-01.jpg");
  background-position: top left;
  background-size: cover;
}

.text-select-mobile {
  position: absolute;
  top: 25%;
  left: 4.25%;
  width: 30.5rem;
  text-align: left;
  padding: 0;
  z-index: 20;
}
.text-select {
  position: absolute;
  top: 15%;
  left: 8.5%;
  width: 30.5rem;
  text-align: left;
  padding: 0;
  z-index: 20;
}

.filter-img {
  height: 100%;
  width: 100%;
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.08) 0%,
    rgba(0, 0, 0, 0.45) 100%
  );
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
}

.title-mobile {
  color: #fff;
  font-weight: 700;
  font-size: 2rem;
  padding-top: 20px;
}
.title {
  color: #fff;
  font-weight: 700;
  font-size: 52px;
  padding-top: 20px;
}

.title::before,
.title-mobile::before {
  width: 30px;
  height: 3px;
  background-color: #ff50c2;
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
}

.subtitle-mobile {
  text-transform: uppercase;
  width: 100%;
  color: #00fff5;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.29px;
  line-height: 35px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.subtitle {
  text-transform: uppercase;
  width: 125%;
  color: #00fff5;
  font-size: 29px;
  font-weight: 600;
  letter-spacing: -0.29px;
  line-height: 35px;
  margin-top: 38px;
  display: flex;
  align-items: center;
  margin-bottom: 54px;
}

.iconCheck {
  color: #00fff5;
  font-size: 30px;
  margin-right: 0.8rem;
}

.city-span {
  display: inline-block;
  margin: 0 0 20px 0;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
}

.select-cidade-mobile {
  width: 100%;
}
.select-cidade {
  width: 138%;
}

.multiselect {
  height: 79px;
  --ms-caret-color: #e91e63;
  --ms-option-bg-pointed: linear-gradient(
    90deg,
    #ffe6f7 0,
    rgba(255, 150, 219, 0)
  );
  color: #747474;
  --ms-placeholder-color: #000;
  --ms-option-py: 1rem;
}

.multiselect-visible {
  z-index: 9999999999;
}

@media screen and (max-width: 450px) {
  .img-fundo-mobile {
    height: 100vh;
    transform: translateX(-20%);
  }

  .text-select-mobile {
    position: relative;
    top: 25%;
    left: 0%;
    width: 100%;
  }
}
</style>
