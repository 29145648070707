<template>
    <div class="containerAll">
        <div class="data-container">
            <h2 class="title">{{ plan.name }}</h2>
            <p class="local">{{ `${plan.cidade} - ${plan.estado}` }}</p>
            <span class="planPrice">{{ toMoney(plan.price/100) }}<span class="mes">/mês</span></span>
        </div>
        <div class="qtd-container">
            <span class="sub" @click="() => shoppingCar.sub(plan)">-</span>
            <span class="qtd">{{ plan.qtd }}</span>
            <span class="sum" @click="() => shoppingCar.sum(plan)">+</span>
        </div>
    </div>
</template>

<script>
import { inject } from '@vue/runtime-core';
import { toMoney } from '../../services/formater';

export default {
    name: "ShoppingCar",
    props: {
        plan: Object
    },
    setup() {
        const shoppingCar = inject("shoppingCar");

        return { toMoney, shoppingCar }
    },
}
</script>

<style scoped>
    .containerAll{
        display: flex;
        justify-content: space-between;
        margin-bottom: 2.25rem;
        padding-right: .5rem;
    }

    .data-container{
        text-align: left;
        max-width: 15rem;
    }

    .title{
        color: #000;
        font-size: 1.0625rem;
        font-weight: 600;
        margin-bottom: 0;
        width: 100%;
    }

    .local{
        color: #4d4d4d;
        font-size: .75rem;
        font-weight: 400;
        letter-spacing: -.24px;
        margin-top: .1875rem;
    }

    .planPrice{
        color: #c90c53;
        font-size: 1rem;
        font-weight: 600;
        margin-top: .3125rem;
        display: flex;
        align-items: center;
    }

    .mes{
        color: #747474;
        font-size: .75rem;
        font-weight: 500;
        display: block;
        margin-left: .1875rem;
    }

    .qtd-container{
        width: 10.375rem;
        height: 2.625rem;
        border-radius: .1875rem;
        border: 1px solid #b7b7b7;
        display: flex;
        align-items: center;
        justify-content: space-around;
        align-self: flex-end;
    }

    .qtd{
        color: #c90c53;
        font-size: 1rem;
        font-weight: 600;
    }

    .sub, .sum{
        color: #000;
        font-size: 1.0625rem;
        font-weight: 500;
        cursor: pointer;
        padding: .25rem 1rem;
    }
</style>