<template>
  <div v-if="store.getters.modal.display" @click="close" class="wrapper animate__animated animate__fadeIn">
    <div @keydown.escape="close" class="modal-container animate__animated animate__fadeIn">
        <!-- Modal header -->
        <header class="modal-header">
            {{ store.getters.modal.title }}
            <span class="material-icons-outlined icon" @click="close">close</span>
        </header>
        <!-- Modal body -->
        <div class="modal-body">
            <p v-html="store.getters.modal.message"></p>
        </div>
        <footer class="modal-footer">
            <button @click="actionCancel" class="btn-cancel">
                {{ store.getters.modal.cancelMessage }}
            </button>
            <button @click="actionConfirm" class="btn-confirm">
                {{ store.getters.modal.confirmMessage }}
            </button>
        </footer>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
export default {
    name: "Modal",
    setup() {
        const store = useStore();
        const close = () => {
        store.commit("modal", { display: false });
        };

        const actionConfirm = () => {
        store.getters.modal.callbackOK();
        close();
        };

        const actionCancel = () => {
        store.getters.modal.callbackCancel();
        };

        return { store, close, actionCancel, actionConfirm };
    },
    };
</script>

<style scoped>
    .wrapper{
        position: fixed;
        z-index: 99999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.75);
        display: table;
        transition: opacity 0.3s ease;
    }

    .modal-container{
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        width: 90vw;
        min-height: 35vh;
        margin: auto;
        z-index: 9999999 !important;
        text-align: justify;
    }

    .modal-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #C90C53;
        color: #fff;
        font-size: 1.75rem;
        font-weight: 600;
        padding: .7rem 1rem;
    }

    .icon{
        cursor: pointer;
        user-select: none;
    }

    .modal-body{
        padding: 1rem;
        color: #50596c;
    }

    .modal-body p{
        margin: 0;
        line-height: 1rem;
        font-size: .98rem;
    }

    .modal-footer{
        padding: 0;
        position: absolute;
        bottom: 0;
        width: 100%;
        border: none;
    }

    .btn-confirm{
        color: #5cb85c;
        background: transparent;
        border: none;
        font-size: 1rem;
        line-height: 1.25rem;
        outline: none;
        padding: 1.5rem 1rem;
        text-align: center;
        user-select: none;
        font-weight: 700;
        margin: 0;
    }

    .btn-cancel{
        color: #d9534f;
        background: transparent;
        border: none;
        font-size: 1rem;
        line-height: 1.25rem;
        outline: none;
        padding: 1.5rem 1rem;
        text-align: center;
        user-select: none;
        font-weight: 700;
        margin: 0;
    }

    @media screen and (min-width: 800px){
        .modal-container{
            width: 60vw;
        }
    }
</style>