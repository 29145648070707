<template>
    <footer v-if="type === 'default'" :class="`${route.path.includes('uniodonto') ? `${isMobile ? 'containerAll-mobile-custom' : 'containerAll'}` : `${isMobile ? 'containerAll-mobile' : 'containerAll'}`}`">
        <div :class="`${isMobile ? 'content-mobile col-11 mx-auto' : 'content col-10 mx-auto'}`">
            <div :class="`${isMobile ? 'comprodonto-mobile' : 'comprodonto'}`">
                <img v-if="!isMobile" src="../../assets/img/footer/footer-12.png" alt="Logotipo da Comprodonto" />
                <p :class="`${isMobile ? 'text-mobile' : 'text'}`">
                    A Comprodonto é um sistema exclusivo de venda de planos odontológicos 
                    da Cooperativa Uniodonto, o maior sistema deste segmento no Brasil.
                </p>
                <router-link to="/sobre" class="link">
                    Saiba mais
                    <span class="material-icons-outlined icon-arrow">navigate_next</span>
                </router-link>
            </div>
            <div :class="`${isMobile ? 'item-social-mobile' : 'item-social'}`">
                <h2 class="subtitle">Siga-nos</h2>
                <div class="social-header">
                    <a
                        href="https://api.whatsapp.com/send?phone=5535933007935&text=Ol%C3%A1,%20gostaria%20de%20saber%20mais%20sobre%20os%20planos%20odontol%C3%B3gicos."
                        target="_blank"
                    >
                        <svg style="width:24px;height:24px;color:#fff;margin: 0 1rem 1rem 0;" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M12.04 2C6.58 2 2.13 6.45 2.13 11.91C2.13 13.66 2.59 15.36 3.45 16.86L2.05 22L7.3 20.62C8.75 21.41 10.38 21.83 12.04 21.83C17.5 21.83 21.95 17.38 21.95 11.92C21.95 9.27 20.92 6.78 19.05 4.91C17.18 3.03 14.69 2 12.04 2M12.05 3.67C14.25 3.67 16.31 4.53 17.87 6.09C19.42 7.65 20.28 9.72 20.28 11.92C20.28 16.46 16.58 20.15 12.04 20.15C10.56 20.15 9.11 19.76 7.85 19L7.55 18.83L4.43 19.65L5.26 16.61L5.06 16.29C4.24 15 3.8 13.47 3.8 11.91C3.81 7.37 7.5 3.67 12.05 3.67M8.53 7.33C8.37 7.33 8.1 7.39 7.87 7.64C7.65 7.89 7 8.5 7 9.71C7 10.93 7.89 12.1 8 12.27C8.14 12.44 9.76 14.94 12.25 16C12.84 16.27 13.3 16.42 13.66 16.53C14.25 16.72 14.79 16.69 15.22 16.63C15.7 16.56 16.68 16.03 16.89 15.45C17.1 14.87 17.1 14.38 17.04 14.27C16.97 14.17 16.81 14.11 16.56 14C16.31 13.86 15.09 13.26 14.87 13.18C14.64 13.1 14.5 13.06 14.31 13.3C14.15 13.55 13.67 14.11 13.53 14.27C13.38 14.44 13.24 14.46 13 14.34C12.74 14.21 11.94 13.95 11 13.11C10.26 12.45 9.77 11.64 9.62 11.39C9.5 11.15 9.61 11 9.73 10.89C9.84 10.78 10 10.6 10.1 10.45C10.23 10.31 10.27 10.2 10.35 10.04C10.43 9.87 10.39 9.73 10.33 9.61C10.27 9.5 9.77 8.26 9.56 7.77C9.36 7.29 9.16 7.35 9 7.34C8.86 7.34 8.7 7.33 8.53 7.33Z" />
                        </svg>
                    </a>
                    <a href="https://www.facebook.com/comprodonto" target="_blank">
                        <svg style="width:24px;height:24px;color:#fff;margin: 0 1rem 1rem 0;" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z" />
                        </svg>
                    </a>
                    <a href="https://www.instagram.com/comprodontooficial/" target="_blank">
                        <svg style="width:24px;height:24px;color:#fff;margin: 0 1rem 1rem 0;" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z" />
                        </svg>
                    </a>
                </div>
            </div>
            <div :class="`${isMobile ? 'item-mobile' : 'item'}`">
                <h2 class="subtitle">Contato</h2>
                <div class="contato-container">
                    <p class="contato">
                        <span class="material-icons-outlined icon-contato">phone_iphone</span>
                        +55 (35) 9 3300-7935
                    </p>
                    <p class="contato">
                        <span class="material-icons-outlined icon-contato">email</span>
                        contato@comprodonto.com.br
                    </p>
                    <p class="contato termsPolitics" @click="() => openModal('terms')">Termos de Uso</p>
                    <p class="contato termsPolitics" @click="() => openModal('politics')">Políticas de Privacidade</p>
                </div>
            </div>
        </div>
    </footer>


    <footer v-else-if="type === 'compra'" :class="`${isMobile ? 'containerAll-compra-mobile' : 'containerAll-compra'}`">
        <div :class="`${isMobile ? 'content-mobile col-11 mx-auto' : 'content col-10 mx-auto'}`">
            <div :class="`${isMobile ? 'comprodonto-mobile' : 'comprodonto'}`">
                <img v-if="!isMobile" src="../../assets/img/footer/footer-13.png" alt="Logotipo da Comprodonto" />
                <p :class="`${isMobile ? 'text-mobile' : 'text'}`">
                    A Comprodonto é um sistema exclusivo de venda de planos odontológicos 
                    da Cooperativa Uniodonto, o maior sistema deste segmento no Brasil.
                </p>
            </div>
            <div :class="`${isMobile ? 'item-payment-mobile' : 'item'}`">
                <h2 class="subtitle-compra">Pagamento</h2>
                <div class="payment-container">
                    <img src="../../assets/img/footer/footer-09.png" alt />
                </div>
            </div>
            <div :class="`${isMobile ? 'item-mobile' : 'item'}`">
                <h2 class="subtitle-compra">Contato</h2>
                <div class="contato-container">
                    <p class="contato">
                        <span class="material-icons-outlined icon-contato-compra">phone_iphone</span>
                        +55 (35) 9 3300-7935
                    </p>
                    <p class="contato">
                        <span class="material-icons-outlined icon-contato-compra">email</span>
                        contato@comprodonto.com.br
                    </p>
                    <p class="contato termsPolitics" @click="() => openModal('terms')">Termos de Uso</p>
                    <p class="contato termsPolitics" @click="() => openModal('politics')">Políticas de Privacidade</p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

export default{
    name: "Footer",
    props: {
        type: String,
        isMobile: Boolean
    },
    setup() {
        const store = useStore();
        const route = useRoute();

        const openModal = (modalType) => store.commit("setModalTermsAndPolitics", {display: true, content: modalType});
        
        return { openModal, route }
    }
}
</script>

<style scoped>
    .containerAll-mobile-custom{
        background-color: #c90c53;
        color: #fff;
        display: flex;
        align-items: center;
        height: 32rem;
        padding-bottom: 5rem;
    }
    .containerAll-mobile{
        background-color: #c90c53;
        color: #fff;
        display: flex;
        align-items: center;
        height: 30rem;
        padding-bottom: 4rem;
    }
    .containerAll{
        background-color: #c90c53;
        color: #fff;
        height: 15.3125rem;
        display: flex;
        align-items: center;
    }
    .containerAll-compra-mobile{
        background-color: #fff;
        color: #747474;
        display: flex;
        align-items: center;
        height: 30rem;
        padding-bottom: 3rem;
        border-top: 1px solid #e3e3e3;
    }
    .containerAll-compra{
        border-top: 1px solid #e3e3e3;
        background-color: #fff;
        color: #747474;
        height: 15.3125rem;
        display: flex;
        align-items: center;
    }

    .content-mobile{
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        padding: 0;
    }
    .content{
        display: flex;
        justify-content: space-between;
        padding: 0;
    }

    .comprodonto{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 45%;
        text-align: left;
    }

    .item-mobile{
        width: 100%;
        text-align: left;
    }
    .item{
        width: 28%;
        text-align: left;
    }

    .item-social-mobile{
        width: 100%;
        text-align: left;
        margin: 1.5rem 0;
    }
    .item-social{
        width: 15%;
        text-align: left;
    }

    .item-payment-mobile{
        width: 100%;
        text-align: left;
        margin: 1.5rem 0;
    }

    .subtitle-compra{
        color: #000;
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: 0.26px;
        text-transform: uppercase;
    }
    .subtitle{
        font-size: 0.75rem;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 1.3125rem;
    }

    .contato{
        display: flex;
        align-items: center;
        font-size: .875rem;
        font-weight: 400;
        line-height: .75rem;
    }

    .icon-contato-compra{
        color: #c90c53;
        font-size: 1rem;
        margin-right: .8rem;
    }
    .icon-contato{
        font-size: 1rem;
        margin-right: .8rem;
    }

    .termsPolitics{
        cursor: pointer;
        -webkit-touch-callout: none; 
        -webkit-user-select: none;   
        -khtml-user-select: none;    
        -moz-user-select: none;       
        -ms-user-select: none;        
        user-select: none;
    }

    .text-mobile{
        text-align: left;
        font-size: .875rem;
        font-weight: 400;
        margin: 1.5rem 0 1.25rem 0;
    }
    .text{
        font-size: .875rem;
        font-weight: 400;
        margin: 0;
        margin-top: 1.5rem;
    }

    .link{
        color: #ff96db;
        font-size: .875rem;
        font-weight: 500;
        display: flex;
        align-items: center;
    }
    .link:hover{
        text-decoration: none;
    }

    .icon-arrow{
        color: #fff;
        font-size: 1rem;
        margin-left: .4rem;
    }
</style>
