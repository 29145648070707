<template>
  <div
    :class="`${
      isMobile
        ? 'containerAll-mobile col-11 mx-auto'
        : 'containerAll col-10 mx-auto'
    }`"
  >
    <section class="banner-duvida">
      <h1 :class="`${isMobile ? 'title-mobile' : 'title'}`">
        Tire suas dúvidas
      </h1>
      <p :class="`${isMobile ? 'subtitle-mobile' : 'subtitle'}`">
        Encontre abaixo as respostas às principais dúvidas.
      </p>
    </section>

    <section class="duvidas" v-for="doubte in doubtes" :key="doubte.id">
      <div
        :class="`${
          isMobile ? 'container-duvidas-mobile' : 'container-duvidas'
        }`"
        @click="() => verificaClick(doubte.id, doubte.active)"
      >
        <div class="container-img-text">
          <div class="container-text">
            <p :class="`${isMobile ? 'question-mobile' : 'question'}`">
              {{ doubte.question }}
            </p>
          </div>
          <span
            v-if="doubte.active"
            class="
              material-icons-outlined
              icon
              animate__animated animate__rotateIn
            "
            >expand_less</span
          >
          <span
            v-else
            class="
              material-icons-outlined
              icon
              animate__animated animate__rotateIn
            "
            >expand_more</span
          >
        </div>

        <div
          v-if="doubte.active"
          class="duvidas-detalhes animate__animated animate__fadeInDown"
        >
          <p class="answer" v-html="doubte.answer"></p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { createEventosSite } from '../../services/util';





export default{
  name: "Doubtes",
  props: {
    isMobile: Boolean
  },
  data() {
    return {
      doubtes: [
        {
          id: 'duvida_1', 
          question: 'Como posso comprar meu plano?',
          answer: `Basta você seguir os passos abaixo:
          <ol><li>Acessar a tela inicial do nosso site e escolher sua cidade;</li> 
          <li>Escolha o plano desejado e quantidade correta;</li> 
          <li>Preencha as informações corretamente e pronto.</li></ol>`,
          active: false
        },
        {
          id: 'duvida_2', 
          question: 'Como obter Atendimento?',
          answer: `Basta acessar o site da 
          <a
              href="http://www.uniodonto.coop.br/encontre-um-dentista/"
              target="_blank"
          >
              Uniodonto
          </a> 
          os telefones e endereços dos dentistas cooperados na localidade em que você deseja o atendimento. Depois é só marcar uma consulta, levando sempre seu cartão Uniodonto e uma identidade, com foto.`,
            active: false
        },
        {
          id: 'duvida_3', 
          question: 'Estou viajando e fora da área de atuação da Uniodonto Poços de Caldas. Eu consigo atendimento?',
          answer: 'Nosso plano é nacional! O atendimento é permitido e acontece em forma de “intercâmbio”.',
          active: false
        },
        {
          id: 'duvida_4', 
          question: 'Como encontro dentistas fora da minha cidade?',
          answer: `Pelo site 
          <a
              href="http://www.uniodonto.coop.br/encontre-um-dentista/"
              target="_blank"
          >
              Uniodonto
          </a> 
          ou pelo aplicativo Uniodonto Poços disponível para iOS e Android.`,
          active: false
        },
        {
          id: 'duvida_5', 
          question: 'Posso mudar de dentista?',
          answer: 'Sim. Por ser um direito do consumidor e por ser o sistema de “livre escolha”, pode ser cancelado um tratamento e solicitar ao novo dentista uma nova guia de atendimento e procurar o mesmo perito para que ele transcreva a sua primeira perícia para esta nova guia de atendimento.',
          active: false
        },
        {
          id: 'duvida_6', 
          question: 'Tenho empresa com CNPJ (PME ou PJ) e gostaria de ter um plano Uniodonto! Como posso fazer?',
          answer: `Empresas com no mínimo três pessoas (podendo ser funcionários ou familiares) poderão se inscrever como plano empresarial! Entre em 
          <a href="/contato" target="_blank">contato</a>
          conosco informando os dados da sua empresa e quantidade de pessoas. Iremos retornar assim que possível.`,
          active: false
        },
        {
          id: 'duvida_7', 
          question: 'Quem são as pessoas que podem ser incluídas como dependentes do plano?',
          answer: `Podem ser inscritos como beneficiários dependentes as pessoas que façam parte do grupo familiar do beneficiário titular, assim entendidos:
          <ul><li>O cônjuge;</li>
          <li>Os filhos.</li>`,
          active: false
        },
        {
          id: 'duvida_8', 
          question: 'O que é CNS?',
          answer: 'CNS é a sigla utilizada para o Cartão Nacional de Saúde. O número do CNS passou a ser informado nos cartões de identificação e no cadastro do beneficiário, tanto titular como dependente, conforme exigência da ANS – Agência Nacional de Saúde Suplementar.',
          active: false
        },
        {
          id: 'duvida_9', 
          question: 'Como encontro o meu número do CNS?',
          answer: `O número do CNS pode ser consultado pelo
          <a href="https://meudigisus.saude.gov.br/pwa/onboard" target="_blank">site</a>.`,
          active: false
        },
        {
          id: 'duvida_10', 
          question: 'Há reembolso para procedimentos não cobertos?',
          answer: `Não. Os atos complementares (procedimentos que não são cobertos pelo plano e que podem ser indicados por seu cirurgião-dentista) dependem de livre negociação entre você e o cirurgião-dentista que lhe prestar atendimento, sem a participação da Uniodonto no processo.`,
          active: false
        },
        {
          id: 'duvida_11', 
          question: 'Posso fazer um plano apenas para uma terceira pessoa, como meu/minha filho(a) ou cônjuge?',
          answer: `Sim. Nesse caso, você poderá se tornar responsável financeiro ou estipulante desse contrato. Você terá todas as responsabilidades contratuais, mas não terá os direitos de um beneficiário do plano. Os terceiros, no caso, terão acesso a todos os benefícios e atendimentos do plano.`,
          active: false
        },
        {
          id: 'duvida_12', 
          question: 'Em quanto tempo poderei utilizar meu plano após a contratação online?',
          answer: `Após a contratação online e aprovação do pagamento, seus documentos serão analisados pela Uniodonto contratada em até 48 horas úteis. Se estiver tudo certo, você receberá a aprovação do seu plano junto ao número de carteirinha diretamente no seu e-mail. Pronto! Você já é um cliente Uniodonto.`,
          active: false
        },
        {
          id: 'duvida_13', 
          question: 'Como é feita a aprovação do meu tratamento de rotina?',
          answer: `Esta é uma facilidade da Comprodonto. Após a solicitação, nossa equipe irá verificar quais as necessidades de tratamento e solicitará autorização dos procedimentos via Web. Caso o beneficiário não possua guia em aberto com outro prestador ou o procedimento nunca foi realizado, o tratamento será autorizado automaticamente.`,
          active: false
        },
        {
          id: 'duvida_14', 
          question: 'Há limites para o número de tratamentos?',
          answer: `Você poderá realizar, sem limites, os tratamentos que possuírem indicação clínica, conforme as condições contratadas.`,
          active: false
        },
      ]
    }
  },
  methods: {
    verificaClick(id, active) {
      this.doubtes.map(doubte => {
        if (id === doubte.id) doubte.active = !active;
      });
      !active && createEventosSite("CLICK", id);
    }
  },
}
</script>

<style scoped>
.containerAll-mobile {
  padding: 0;
  padding-top: 9rem;
  padding-bottom: 6rem;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  text-align: left;
}
.containerAll {
  padding: 0;
  padding-top: 12rem;
  padding-bottom: 6rem;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  text-align: left;
}

.title-mobile {
  color: #c90c53;
  font-size: 6.5vw;
  line-height: unset;
  margin-bottom: 18px;
  font-weight: 700;
}
.title {
  color: #00f5ff;
  font-size: 2.8125rem;
  font-weight: 700;
  letter-spacing: -0.9px;
  line-height: 3rem;
  margin-bottom: 1.5625rem;
}

.subtitle-mobile {
  font-size: 4vw;
  color: #4b4b4b;
  font-weight: 500;
  margin-bottom: 5rem;
}
.subtitle {
  color: #000;
  font-size: 1.5625rem;
  font-weight: 600;
  width: 39.5rem;
  line-height: normal;
  margin-bottom: 6rem;
}

.duvidas {
  width: 100%;
  display: grid;
}

.container-duvidas-mobile {
  position: relative;
  grid-area: 1/1/2/2;
  padding: 2rem 1rem;
  border: 1px solid #c2ccd9;
  background-color: #fff;
  flex-direction: column;
  margin-bottom: 1.25rem;
}
.container-duvidas {
  position: relative;
  grid-area: 1/1/2/2;
  padding: 2rem 5rem 2rem 5rem;
  border: 1px solid #c2ccd9;
  background-color: #fff;
  flex-direction: column;
  margin-bottom: 1.25rem;
}

.question-mobile {
  width: 80%;
  color: #000;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5625rem;
  margin-right: 0;
  margin-bottom: 2rem;
}
.question {
  width: 90%;
  color: #000;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5625rem;
  margin-bottom: 2rem;
}

.answer {
  color: #c60151;
  font-size: 0.875rem;
  font-weight: 400;
}

.icon {
  color: #ff6ea9;
  position: absolute;
  top: 2rem;
  right: 10%;
  font-size: 2rem;
  cursor: pointer;
}
</style>