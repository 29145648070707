<template>
  <section
    :class="`${
      isMobile
        ? 'containerAll-mobile col-11 mx-auto'
        : 'containerAll col-10 mx-auto'
    }`"
  >
    <div class="contato-container">
      <h1 :class="`${isMobile ? 'title-mobile' : 'title'}`">Contato</h1>
      <h3 v-if="!isMobile" class="text">
        Preencha os campos abaixo e nos envie sua mensagem, estamos a disposição
        para te ajudar. Será um prazer atendê-lo.
      </h3>
    </div>
    <div class="form-container">
      <form :class="`${isMobile ? 'form-mobile' : 'form'}`">
        <Input
          type="default"
          placeholder="Nome"
          className="input-contato"
          v-model="data.nome"
        />
        <Input
          type="default"
          placeholder="Email"
          className="input-contato"
          v-model="data.email"
        />
        <Input
          type="tel"
          placeholder="Telefone"
          className="input-contato"
          v-model="data.telefone"
        />
        <Input
          type="textarea"
          placeholder="Mensagem"
          v-model="data.mensagem"
          :rows="5"
          :cols="30"
          className="textarea-contato"
        />
        <!-- <Button :click="recaptcha" type="enviar-contato" text="Enviar" /> -->
        <Button :click="sendMessage" type="enviar-contato" text="Enviar" />
      </form>

      <!-- <vue-recaptcha
        ref="recaptcha"
        @verify="onCaptchaVerified"
        @expired="onCaptchaExpired"
        size="invisible"
        sitekey="6LfTBDQeAAAAABkYjQnG_QlQC6FfSTml3LJoxkW8">
      </vue-recaptcha> -->

      <div v-if="!isMobile" class="contato">
        <img class="img" src="../../assets/img/contato/contato-01.png" alt />
        <p class="tel">+55 (35) 9 3300-7935</p>
        <p class="email">contato@comprodonto.com.br</p>
        <span class="social-container">
          Siga-nos
          <a
            href="https://www.instagram.com/comprodontooficial/"
            target="_blank"
          >
            <svg
              style="
                width: 26px;
                height: 26px;
                color: #adadad;
                margin: 0 0.5rem;
              "
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z"
              />
            </svg>
          </a>
          <a
            href="https://www.facebook.com/comprodonto"
            target="_blank"
          >
            <svg
              style="
                width: 26px;
                height: 26px;
                color: #adadad;
                margin: 0 0.5rem;
              "
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z"
              />
            </svg>
          </a>
        </span>
      </div>
    </div>
  </section>
</template>

<script>

import Input from "../Elements/Input.vue";
import Button from "../Elements/Button.vue";
import { inject, ref } from "vue";
import { isEmail, isEmpty, isName } from '../../services/validate';
import { POST } from '../../services/api';
// import { VueRecaptcha } from 'vue-recaptcha';


export default {
  name: "Contacts",
  components: {
    Input,
    Button,
    // VueRecaptcha
  },
  props: {
    isMobile: Boolean,
  },
  // methods: {
  //   recaptcha: function () {
  //     this.$refs.recaptcha.execute();
  //   },

  //   onCaptchaVerified: function (recaptchaToken) {
  //     this.sendMessage(recaptchaToken);
  //     this.$refs.recaptcha.reset();
  //   },

  //   onCaptchaExpired: function () {
  //     this.$refs.recaptcha.reset();
  //   }
  // },
  setup() {
    const alert = inject("alert");
    const loader = inject("loading");

    const data = ref({
      nome: '',
      email: '',
      telefone: '',
      mensagem: ''
    });

    // const sendMessage = async (token) => {
    const sendMessage = async () => {
      loader.open();
      // if(token) {
        const validate = isEmpty(data.value);
        const emailValidate = isEmail(data.value.email);
        const nameValidate = isName(data.value.nome);
        if(validate && emailValidate && nameValidate) {
          try{
            await POST("contato", data.value);
            alert.open('Sucesso', 'Mensagem encaminhada com successo!');
            data.value = {
              nome: '',
              email: '',
              telefone: '',
              mensagem: '',
            };
          } catch(e) {
            alert.open('Erro', 'Desculpe, ocorreu um erro ao enviar sua mensagem!');
          }
        } else {
          if(!validate) alert.open("Atenção!", "Verifique se todos os campos foram preechidos corretamente.");
          else if(!emailValidate) alert.open("Atenção!", "Email inválido.");
          else if(!nameValidate) alert.open("Atenção!", "Nome não deve conter caracteres especiais ou números.");
        }
      // }
      loader.close();
    };

    return{ data, sendMessage };
   },
 
};
</script>

<style scoped>
.containerAll-mobile {
  padding: 0;
  padding-top: 6rem;
  padding-bottom: 5rem;
}
.containerAll {
  padding: 0;
  padding-top: 12rem;
  padding-bottom: 20rem;
}

.contato-container {
  text-align: left;
  width: 50%;
}

.title-mobile {
  color: #c90c53;
  font-size: 1.375rem;
  font-weight: 700;
  text-transform: uppercase;
  padding-top: 1.625rem;
  margin-bottom: 1.625rem;
  position: relative;
}
.title {
  color: #c90c53;
  font-size: 1.375rem;
  font-weight: 700;
  text-transform: uppercase;
  padding-top: 1.625rem;
  margin-bottom: 1.625rem;
  position: relative;
}
.title:before {
  content: "";
  display: block;
  width: 2.8125rem;
  height: 0.0625rem;
  background-color: #ff50c2;
  position: absolute;
  top: 0;
  left: 0;
}

.text {
  color: #000;
  font-size: 0.9375rem;
  font-weight: 500;
  margin-bottom: 4rem;
}

.form-container {
  display: flex;
  justify-content: space-between;
}

.form-mobile {
  display: flex;
  flex-flow: column;
  width: 100%;
}
.form {
  display: flex;
  flex-flow: column;
  width: 50%;
}

.contato {
  width: 35%;
  align-self: flex-end;
  text-align: left;
}

.img {
  margin-bottom: 2rem;
}

.tel {
  color: #000;
  font-size: 1.125rem;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.tel::before,
.social-container::before {
  content: "";
  display: block;
  width: 1.25rem;
  height: 0.0625rem;
  background-color: #f5267a;
  position: absolute;
  top: 0;
}

.email {
  color: #767676;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.375rem;
  margin-bottom: 2rem;
}

.social-container {
  color: #767676;
  display: flex;
  align-items: center;
  position: relative;
  padding-top: 0.625rem;
}

.social-container a:first-child {
  margin-left: 2rem;
}
</style>
