<template>
    <div :class="`${isMobile ? 'data-container-mobile' : 'data-container'}`">
        <div :class="`${isMobile ? 'section-mobile' : 'section'}`">
            <div :class="`${isMobile ? 'subscriber-header-mobile' : 'subscriber-header'}`">
                <h2 class="subtitle">Dados da Cobrança</h2>
            </div>
            <div :class="`${isMobile ? 'info-mobile' : 'info flex'}`">
                <span class="material-icons-outlined iconCard">credit_card</span>
                <div :class="`${isMobile ? 'cardData-mobile' : 'cardData'}`">
                    <p class="bandeira">{{ store.getters.sidebarDadosCartao.creditCardRegistered.brand }}</p>
                    <p class="cartao">{{ `${data.usuario.name} ${data.usuario.last_name}` }}</p>
                    <p class="cartao">**** **** **** {{ store.getters.sidebarDadosCartao.creditCardRegistered.cardNumber.substring(12) }}</p>
                </div>
            </div>
        </div>

        <div :class="`${isMobile ? 'section-mobile' : 'section'}`">
            <div :class="`${isMobile ? 'info-mobile' : 'info'}`">
                <h2 class="subtitle-custom">Plano anual, cobranças mensais</h2>
                <p>O pagamento está programado para todo <span class="emphasis">dia {{ data.cartao.next }}</span></p>
            </div>
        </div>

        <div :class="`${isMobile ? 'btnContainer-mobile' : 'btnContainer'}`">
            <Button 
                text="Alterar cartão" 
                type="meus-dados" 
                :click="() => canEditCard ? store.commit('showSidebarDadosCartao', true) : openAlert()" 
            />
        </div>
    </div>
</template>

<script>
import { inject, ref } from 'vue';
import { useStore } from 'vuex';
import { numberToCpf } from '../../../services/formater';
import Button from '../../Elements/Button.vue';
export default {
    name: "CardCartao",
    components: {
        Button
    },
    props: {
        data: Object,
        isMobile: Boolean
    },
    created() {
        const store = useStore();
        store.commit("setSidebarDadosCartao", {
            display: false,
            id_subs: this.data.subscriptions[0].id_subs,
            creditCardRegistered: {
                brand: this.data.cartao.bandeira,
                cardNumber: `************${this.data.cartao.numbers}`,
                expirationDate: '',
                holder: '',
                securityCode: ''
            }
        });

        this.canEditCard = this.data.subscriptions[0].plan.recorrencia ? true : false;
    },
    setup(props) {
        const store = useStore();
        const alert = inject("alert");

        const canEditCard = ref(false);

        const openAlert = () => {
            alert.open(
                "Atenção!", 
                `Para alterar seu cartão entre em contato com a uniodonto pelo telefone
                ${props.data.subscriptions[0].plan.provider.telefone} 
                ou pelo e-mail ${props.data.subscriptions[0].plan.provider.email}.`
            );
        };

        return { numberToCpf, store, canEditCard, openAlert }
    },
}
</script>

<style scoped>
    .data-container-mobile{
        width: 100%;
        box-shadow: 4px 4px 0 rgb(109 109 109 / 9%);
        border: 1px solid #e2e2e2;
        background-color: #ffffff;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 4rem;
        padding: 1.5rem;
    }
    .data-container{
        width: 23.6875rem;
        box-shadow: 4px 4px 0 rgb(109 109 109 / 9%);
        border: 1px solid #e2e2e2;
        background-color: #ffffff;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 4rem;
        padding: 2.5rem;
    }

    .data-container .section:first-child, .data-container-mobile .section:first-child{
        margin-bottom: 2.5rem;
    }

    .section-mobile{
        width: 80%;
        margin-bottom: 2rem;
    }
    .section{
        width: 100%;
    }

    .subtitle{
        color: #c90c53;
        font-size: .75rem;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 0;
    }
    
    .subtitle-custom{
        color: #c90c53;
        font-size: .875rem;
        font-weight: 400;
        margin-bottom: .7rem;
    }

    .subscriber-header-mobile{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: .875rem;
    }
    .subscriber-header{
        display: flex;
        align-items: center;
        margin-bottom: .875rem;
    }

    .iconCard{
        color: #D30C53;
        font-size: 4rem;
        align-self: flex-start;
    }

    .cardData-mobile{
        width: 100%;
    }
    .cardData{
        width: 70%;
    }

    .info-mobile p{
        margin-bottom: .2rem;
    }
    .info p{
        margin-bottom: .2rem;
    }

    .info-mobile{
        color: #343434;
        font-size: .875rem;
        font-weight: 400;
        line-height: 1.375rem;
        margin-top: 1rem;
        margin-left: 0;
        text-align: center;
    }
    .info{
        color: #343434;
        font-size: .875rem;
        font-weight: 400;
        line-height: 1.375rem;
        margin-top: 1.0625rem;
        text-align: left;
    }
    .flex{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .emphasis{
        font-weight: 700;
    }

    .bandeira{
        color: #343434;
        font-weight: 700;
    }

    .cartao{
        color: #747474;
    }

    .btnContainer-mobile{
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: .5rem;
    }
    .btnContainer{
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: .5rem;
    }
</style>