<template>
    <section :class="`${isMobile ? 'missao-empresa-mobile' : 'missao-empresa'}`">
        <div :class="`${isMobile ? 'content-mobile col-11 mx-auto' : 'content col-10 mx-auto'}`">
            <div :class="`${isMobile ? 'missao-mobile text-empresa' : 'missao text-empresa'}`">
                <div class="text-img">
                <img src="../../assets/img/sobre/sobre-02.png" alt />
                <h1 :class="`${isMobile ? 'title-mobile' : 'title'}`">Missão</h1>
                </div>
                <p :class="`${isMobile ? 'text-mobile' : 'text'}`">Ser uma marketplace de planos odontológicos, oferecendo aos nossos usuários mais informações durante a escolha dos planos, buscando sempre o aperfeiçoamento e excelência no atendimento.</p>
            </div>

            <div :class="`${isMobile ? 'visao-mobile text-empresa' : 'visao text-empresa'}`">
                <div class="text-img">
                <img src="../../assets/img/sobre/sobre-03.png" alt />
                <h1 :class="`${isMobile ? 'title-mobile' : 'title'}`">Visão</h1>
                </div>
                <p :class="`${isMobile ? 'text-mobile' : 'text'}`">Promover a saúde bucal por meio de propagação de informações, tratamento personalizado, segurança, tranquilidade e acima de tudo responsabilidade.</p>
            </div>

            <div :class="`${isMobile ? 'valores-mobile text-empresa' : 'valores text-empresa'}`">
                <div class="text-img">
                <img src="../../assets/img/sobre/sobre-04.png" alt />
                <h1 :class="`${isMobile ? 'title-mobile' : 'title'}`">valores</h1>
                </div>
                <ul class="list">
                    <li>
                        <p :class="`${isMobile ? 'text-mobile' : 'text'}`">– Respeito ao paciente;</p>
                    </li>

                    <li>
                        <p :class="`${isMobile ? 'text-mobile' : 'text'}`">– Atendimento de qualidade;</p>
                    </li>
                    <li>
                        <p :class="`${isMobile ? 'text-mobile' : 'text'}`">– Confiabilidade;</p>
                    </li>
                    <li>
                        <p :class="`${isMobile ? 'text-mobile' : 'text'}`">– Excelência;</p>
                    </li>
                    <li>
                        <p :class="`${isMobile ? 'text-mobile' : 'text'}`">– Modernidade;</p>
                    </li>
                    <li>
                        <p :class="`${isMobile ? 'text-mobile' : 'text'}`">– Ética;</p>
                    </li>
                    <li>
                        <p :class="`${isMobile ? 'text-mobile' : 'text'}`">– Transparência.</p>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</template>

<script>

export default{
    name: "Business",
    props: {
        isMobile: Boolean
    },
}
</script>

<style scoped>
    .missao-empresa-mobile{
        background-color: #fff;
        padding-top: 2.9375rem;
        padding-bottom: 5rem;
    }
    .missao-empresa{
        background-image: linear-gradient(270deg,#a70f49,#ae0e4b);
        padding-top: 2.9375rem;
        padding-bottom: 5rem;
    }

    .content-mobile{
        padding: 0;
    }
    .content{
        display: flex;
        justify-content: space-between;
        padding: 0;
    }

    .missao-mobile, .valores-mobile{
        width: 100%;
        text-align: left;
    }
    .missao, .valores{
        width: 15.625rem;
        text-align: left;
    }

    .visao-mobile{
        width: 100%;
        margin: 3rem 0;
        text-align: left;
    }
    .visao{
        width: 272px;
        margin-left: 7.3125rem;
        margin-right: 7.3125rem;
        text-align: left;
    }

    .text-empresa{
        position: relative;
        z-index: 20;
    }

    .title-mobile{
        color: #000;
        font-size: 1.125rem;
        font-weight: 700;
        text-transform: uppercase;
        margin-left: 1.0625rem;
        margin-bottom: 0;
    }
    .title{
        color: #fff;
        font-size: 1.125rem;
        font-weight: 700;
        text-transform: uppercase;
        margin-left: 1.0625rem;
    }

    .text-img {
        display: flex;
        align-items: center;
        margin-bottom: 1.375rem;
    }

    .text-mobile{
        color: #747474;
        font-size: .875rem;
        font-weight: 400;
        margin-bottom: .3rem;
    }
    .text{
        color: #ff96db;
        font-size: .875rem;
        font-weight: 400;
        margin-bottom: .3rem;
    }

    .list{
        list-style: none;
        padding: 0;
        margin: 0;
    }
</style>
