<template>
    <div 
        class="sidebar-container" 
        v-if="store.getters.sidebarDadosTitular.display" 
    >
        <div class="background animate__animated animate__fadeIn" @click="closeSidebar"></div>
        <div class="content-container animate__animated animate__fadeInRight">
            <div class="sidebar-top">
                <h1 class="title">Dados do Titular</h1>
                <span @click="closeSidebar" class="material-icons-outlined iconClose">close</span>
            </div>
            <form :class="`${isMobile ? 'form-mobile' : 'form'}`">
                <div class="row">
                    <div class="inputContainer col-12">
                        <label>Telefone</label>
                        <Input
                            type="tel"
                            placeholder="Telefone"
                            className="input-sidebar-minhaArea"
                            v-model="store.getters.sidebarDadosTitular.data.telefone"
                        />
                    </div>
                </div>
                
                <div class="row">
                    <div class="inputContainer col-12">
                        <label>CEP</label>
                        <Input
                            type="cep"
                            placeholder="CEP"
                            className="input-sidebar-minhaArea"
                            v-model="store.getters.sidebarDadosTitular.data.zip_code"
                            :blur="getCep"
                        />
                    </div>
                </div>

                <div class="row" v-if="!isMobile">
                    <div class="inputContainer col-6">
                        <label>Rua</label>
                        <Input
                            type="default"
                            placeholder="Rua"
                            className="input-sidebar-minhaArea"
                            readonly
                            v-model="store.getters.sidebarDadosTitular.data.street"
                        />
                    </div>
                    <div class="inputContainer col-6">
                        <label>Bairro</label>
                        <Input
                            type="default"
                            placeholder="Bairro"
                            className="input-sidebar-minhaArea"
                            readonly
                            v-model="store.getters.sidebarDadosTitular.data.district"
                        />
                    </div>
                </div>
                <div class="row" v-if="isMobile">
                    <div class="inputContainer col-12">
                        <label>Rua</label>
                        <Input
                            type="default"
                            placeholder="Rua"
                            className="input-sidebar-minhaArea"
                            readonly
                            v-model="store.getters.sidebarDadosTitular.data.street"
                        />
                    </div>
                </div>
                <div class="row" v-if="isMobile">
                    <div class="inputContainer col-12">
                        <label>Bairro</label>
                        <Input
                            type="default"
                            placeholder="Bairro"
                            className="input-sidebar-minhaArea"
                            readonly
                            v-model="store.getters.sidebarDadosTitular.data.district"
                        />
                    </div>
                </div>

                <div class="row" v-if="!isMobile">
                    <div class="inputContainer col-6">
                        <label>Cidade</label>
                        <Input
                            type="default"
                            placeholder="Cidade"
                            className="input-sidebar-minhaArea"
                            readonly
                            v-model="store.getters.sidebarDadosTitular.data.city"
                        />
                    </div>
                    <div class="inputContainer col-6">
                        <label>Estado</label>
                        <Input
                            type="default"
                            placeholder="Estado"
                            className="input-sidebar-minhaArea"
                            readonly
                            v-model="store.getters.sidebarDadosTitular.data.state"
                        />
                    </div>
                </div>
                <div class="row" v-if="isMobile">
                    <div class="inputContainer col-12">
                        <label>Cidade</label>
                        <Input
                            type="default"
                            placeholder="Cidade"
                            className="input-sidebar-minhaArea"
                            readonly
                            v-model="store.getters.sidebarDadosTitular.data.city"
                        />
                    </div>
                </div>
                <div class="row" v-if="isMobile">
                    <div class="inputContainer col-12">
                        <label>Estado</label>
                        <Input
                            type="default"
                            placeholder="Estado"
                            className="input-sidebar-minhaArea"
                            readonly
                            v-model="store.getters.sidebarDadosTitular.data.state"
                        />
                    </div>
                </div>

                <div class="row" v-if="!isMobile">
                    <div class="inputContainer col-6">
                        <label>Nº Endereço</label>
                        <Input
                            type="number"
                            placeholder="Nº Endereço"
                            className="input-sidebar-minhaArea"
                            :min="0"
                            v-model="store.getters.sidebarDadosTitular.data.street_number"
                        />
                    </div>
                    <div class="inputContainer col-6">
                        <label>Complemento</label>
                        <Input
                            type="default"
                            placeholder="Complemento"
                            className="input-sidebar-minhaArea"
                            v-model="store.getters.sidebarDadosTitular.data.complement"
                        />
                    </div>
                </div>
                <div class="row" v-if="isMobile">
                    <div class="inputContainer col-12">
                        <label>Nº Endereço</label>
                        <Input
                            type="number"
                            placeholder="Nº Endereço"
                            className="input-sidebar-minhaArea"
                            :min="0"
                            v-model="store.getters.sidebarDadosTitular.data.street_number"
                        />
                    </div>
                </div>
                <div class="row" v-if="isMobile">
                    <div class="inputContainer col-12">
                        <label>Complemento</label>
                        <Input
                            type="default"
                            placeholder="Complemento"
                            className="input-sidebar-minhaArea"
                            v-model="store.getters.sidebarDadosTitular.data.complement"
                        />
                    </div>
                </div>

                <Button type="sidebar-minhaArea" text="Editar informações" :click="editInfo" />
            </form>
        </div>
    </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import Input from '../../Elements/Input.vue';
import Button from '../../Elements/Button.vue';
import { inject } from 'vue';
import { decodeJwt } from '../../../services/util';
import { isEmpty } from '../../../services/validate';
import { GET, PUT } from '../../../services/api';

export default {
    name: "SidebarDadosTitular",
    props: {
        isMobile: Boolean
    },
    components: {
        Input,
        Button
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        const alert = inject("alert");
        const loader = inject("loading");

        const closeSidebar = () => store.commit("setSidebarDadosTitular", {display: false, data: store.getters.sidebarDadosTitular.data});

        const editInfo = async () => {
            loader.open();
            let info = Object.assign({}, store.getters.sidebarDadosTitular.data);
            info.area_code = info.telefone.split(")")[0].replace(/\D/g, "");
            info.number = info.telefone.split(")")[1].replace(/\D/g, "");
            info.zip_code = info.zip_code.replace(/\D/g, "");
            delete info.telefone
            const token = JSON.parse(localStorage.getItem("token_comprodonto"));
            let decoded = decodeJwt(token);
            const validate = isEmpty(info)
            
            if(validate) {
                try{
                    await PUT(`usuarios/${decoded.sub}`, 
                    {
                        "phones": {
                            "number": info.number,
                            "area_code": info.area_code
                        },
                        "subscriber": {
                            "street": info.street,
                            "zip_code": info.zip_code,
                            "district": info.district,
                            "city": info.city,
                            "state": info.state,
                            "street_number": info.street_number,
                            "complement": info.complement
                        }
                    });
                    alert.open("Sucesso", "Dados atualizados com sucesso!");
                    closeSidebar();
                } catch (e) {
                    alert.open("Erro!", e.message);
                }
            }
            else {
                alert.open("Atenção!", "Preencha todos os campos.");
            }
            loader.close();
        };

        const getCep = async () => {
            loader.open();
            if (store.getters.sidebarDadosTitular.data.zip_code.length > 8) {
                try{
                    const response = await GET(`https://viacep.com.br/ws/${store.getters.sidebarDadosTitular.data.zip_code}/json/`);
                    store.commit("setSidebarDadosTitular", {display: true, data: store.getters.sidebarDadosTitular.data, getCep: response});
                } catch(e) {
                    alert.open("Erro", "Houve um erro ao buscar o CEP")
                }
            }
            loader.close();
        };

        return { closeSidebar, store, router, editInfo, getCep }
    },
}
</script>

<style scoped>
    .sidebar-container{
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        width: 100%;
        height: 100vh;
    }

    .background{
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,.33);
        width: 100%;
        height: 100vh;
    }

    .content-container{
        position: fixed;
        top: 0;
        right: 0;
        background-color: #fff;
        width: 36rem;
        height: 100vh;
        padding: 2rem 4rem;
    }

    .sidebar-top{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .title{
        color: #c90c53;
        font-size: 1rem;
        font-weight: 500;
        line-height: .9375rem;
        text-transform: uppercase;
    }

    .iconClose{
        color: #C90C53;
        font-size: 2rem;
        user-select: none;
        cursor: pointer;
    }

    .inputContainer{
        position: relative;
        margin: 1.25rem 0;
    }

    .inputContainer label{
        position: absolute;
        top: -.5rem;
        left: 2rem;
        background-color: #fff;
        z-index: 10;
        padding: 0 .3rem;
        color: #4b4b4b;
        font-size: .75rem;
        font-weight: 500;
        line-height: .9675rem;
    }

    .inputContainer:hover > label{
        color: #ff96db;
    }

    .form-mobile{
        overflow-y: auto;
        height: 100vh;
        margin-top: 1rem;
        padding-bottom: 10rem;
        padding-right: .5rem;
    }

    @media screen and (max-width: 584px) {
        .content-container{
            width: 100%;
            padding: 2rem 1.5rem;
        }
    }
</style>