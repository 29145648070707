<template>
  <Navbar type="default" :background="background_navbar" :isMobile="isMobile" />
  <Banner :isMobile="isMobile" />
  <Business :isMobile="isMobile" />
  <Footer type="default" :isMobile="isMobile" />
</template>

<script>
import { ref } from 'vue';
import Navbar from '../components/Elements/Navbar.vue';
import Banner from '../components/Sobre/BannerSobre.vue';
import Business from '../components/Sobre/Business.vue';
import Footer from '../components/Elements/Footer.vue';

export default {
  name: "Home",
  components:{
    Navbar,
    Banner,
    Business,
    Footer
  },
  created() {
    this.isMobile = window.innerWidth < 800 ?  true : false;
    this.background_navbar = window.scrollY < 110 ?  false : true;

    window.addEventListener('resize', () => {
      this.isMobile = window.innerWidth < 800 ?  true : false;
    })
    window.addEventListener('scroll', () => {
      this.background_navbar = window.scrollY < 110 ?  false : true;
    })
  },
  setup() {
    const isMobile = ref(false);
    const background_navbar = ref(false);

    return { isMobile, background_navbar }
  }
};
</script>

<style scoped>
 
</style>
