<template>
    <template v-if="store.getters.modalLogin.display">
        <div class="modal-mask animate__animated animate__fadeIn" @click="closeModal"></div>
        <div class="modal-container animate__animated animate__fadeIn">
            <div class="loginHeader">
                <img class="logo" src="../../assets/img/login/login-01.png" alt="Logotipo Comprodonto" />
                <span @click="closeModal" class="material-icons-outlined iconClose">close</span>
            </div>
            <form v-if="loginForm" class="form">
                <div class="inputContainer">
                    <label class="label">Email</label>
                    <Input
                        type="default"
                        placeholder="Email"
                        className="input-login"
                        autocomplete="off"
                        v-model="info.login"
                    />
                </div>
                <div class="inputContainer">
                    <label class="label">Senha</label>
                    <Input
                        type="password"
                        placeholder="Senha"
                        className="input-login"
                        autocomplete="new-password"
                        v-model="info.senha"
                    />
                </div>
                <Button type="login" text="Avançar" :click="login" />
                <p @click="handleForgotPasswordForm" class="forgotPassword">
                    Esqueci minha senha
                </p>
            </form>
            <form v-else class="form">
                <Button 
                    :click="handleForgotPasswordForm" 
                    type="voltar-login"
                    text="Voltar para login"
                />
                <div class="inputContainer">
                    <label class="label">Email</label>
                    <Input
                        type="default"
                        placeholder="Email"
                        className="input-login"
                        v-model="email_recuperacao"
                    />
                </div>
                <Button type="login" text="Recuperar Senha" :click="recuperarSenha" />
            </form>
        </div>
    </template>
</template>

<script>
import { inject, ref } from 'vue';
import { useStore } from 'vuex';
import Button from './Button.vue';
import Input from './Input.vue';
import { isEmail, isEmpty } from '../../services/validate';
import { GET, POST } from '../../services/api';
import { decodeJwt, setCookieWithDomain } from '../../services/util';
import { useRouter } from 'vue-router';

export default {
    name: "Login",
    components:{
        Button,
        Input
    },
    setup() {
        const store = useStore();
        const alert = inject("alert");
        const loader = inject("loading");
        const shoppingCar = inject("shoppingCar");
        const router = useRouter();

        const loginForm = ref(true);
        const info = ref({
            login: '',
            senha: ''
        });
        const isVerified = ref(null);
        const email = ref("");
        const email_recuperacao = ref("");

        const closeModal = () => {
            store.commit("setModalLogin", {display: false}); 
            loginForm.value = true;
            info.value = {
                login: '',
                senha: ''
            };
            email_recuperacao.value = "";
        };

        const handleForgotPasswordForm = () => loginForm.value = !loginForm.value;

        const login = async () => {
            loader.open();
            const loginValidate = isEmail(info.value.login.trim());
            const validate = isEmpty(info.value);
            const user = store.getters.user;
            console.log(user)
			if(validate && loginValidate) {
                try{
                    const responseLogin = await POST('loginv3', info.value);
                    const token = responseLogin.usuario.token;
                    localStorage.setItem("token_comprodonto", JSON.stringify(token));
                    if (responseLogin.lead) {
                        const domain = process.env.NODE_ENV === 'production' ? ".comprodonto.com.br" : window.location.hostname;
                        setCookieWithDomain('lead_id_cookie', responseLogin.lead.id, 14, domain);
                    }
                    let decoded = decodeJwt(token);
                    const response = await GET(`usuarios/${decoded.sub}`);
                    user.name=response.name
                    user.last_name=response.last_name
                    user.email=response.login
                    user.birth_date=response.subscriber.birth_date
                    user.city=response.subscriber.city
                    user.complement=response.subscriber.complement
                    user.cpf=response.subscriber.cpf
                    user.district=response.subscriber.district
                    user.state=response.subscriber.state
                    user.street=response.subscriber.street
                    user.street_number=response.subscriber.street_number
                    user.zip_code=response.subscriber.zip_code
                    localStorage.setItem("user", JSON.stringify(user));
                    store.commit("setUser", user);
                    isVerified.value = response.verified_at;
                    email.value = response.email;
                    if(isVerified.value) {
                        if(responseLogin.rota === '/minha-area') router.push('/minha-area')
                        else if (responseLogin.rota) router.push('/finalizar-compra' + responseLogin.rota)
                        else router.push('/')
                    } else {
                        localStorage.setItem("email_notVerified_comprodonto", JSON.stringify(email.value));
                        router.push("/verifica-email");
                    }
                    localStorage.setItem("planos", JSON.stringify(responseLogin.carrinho));
                    shoppingCar.start();
                    store.commit("setTextSidebar", "Minha área");
                    closeModal();
				} catch (e) {
                    alert.open(e.title, e.message);
                    info.value = {
                        login: '',
                        senha: ''
                    };
                }
			} else {
                if(!validate) alert.open("Atenção!", "Verifique se todos os campos estão preenchidos.");
                if(!loginValidate) alert.open("Atenção!", "Email inválido.");
            }
            loader.close();
        };

        const recuperarSenha = async () => {
			loader.open();
            const emailValidate = isEmail(email_recuperacao.value);
            if (emailValidate) {
                try{
                    await GET(`redefinir_senha/${email_recuperacao.value}`);
                    alert.open("Sucesso", "Uma nova senha foi enviada a seu e-mail");
                    loginForm.value = true;
                    email_recuperacao.value = "";
                } catch (e) {
                    alert.open("Erro", "Ocorreu um erro ao requisitar uma nova senha a esse e-mail, tente novamente mais tarde")
                    email_recuperacao.value = "";
                }
            } else {
                alert.open("Atenção!", "Email inválido.");
            }
            loader.close();
        };

        return { 
            closeModal, 
            store, 
            loginForm, 
            handleForgotPasswordForm, 
            login, 
            recuperarSenha, 
            email_recuperacao, 
            info 
        }
    },
}
</script>

<style scoped>
    .modal-enter .modal-container,
    .modal-leave-active {
        opacity: 0;
    }

    .modal-mask{
        position: fixed;
        z-index: 999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .75);
        display: table;
        transition: opacity 0.3s ease;
    }

    .modal-container{
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #c70851;
        width: 25rem;
        height: 66vh;
        margin: auto;
        z-index: 999;
        text-align: justify;
        color: #fff;
        padding: 2rem;
        border-radius: 10px;
    }

    .modal-container p:last-child{
        padding-bottom: 2rem;
    }

    .form{
        display: flex;
        flex-flow: column;
        width: 100%;
        margin-top: 4rem;
    }

    .inputContainer{
        position: relative;
        margin-bottom: .8rem;
    }

    .label{
        position: absolute;
        top: -1.2rem;
        left: 1rem;
        z-index: 10;
        margin: 0;
        color: #ff96db;
        font-size: 1rem;
        font-weight: 500;
        line-height: .9375rem;
    }

    .logo{
        width: 70%;
    }
    
    .forgotPassword{
        color: #fff;
        font-size: .875rem;
        font-weight: 700;
        display: block;
        text-align: center;
        margin: 1.5rem auto;
        cursor: pointer;
    }

    .loginHeader{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .iconClose{
        font-size: 2rem;
        cursor: pointer;
        user-select: none;
    }

    @media screen and (max-width: 500px) {

        .modal-container{
            width: 100%;
            height: 100vh;
            background-color: #c70851;
            margin: auto;
            z-index: 999;
            text-align: justify;
            color: #fff;
            padding: 2rem;
            border-radius: 0;
        }
    }


</style>
