<template>
    <div 
        class="sidebar-container" 
        v-if="store.getters.sidebarSenha" 
    >
        <div class="background animate__animated animate__fadeIn" @click="closeSidebar"></div>
        <div class="content-container animate__animated animate__fadeInRight">
            <div class="sidebar-top">
                <h1 class="title">Alterar senha</h1>
                <span @click="closeSidebar" class="material-icons-outlined iconClose">close</span>
            </div>
            <form :class="`${isMobile ? 'form-mobile' : 'form'}`">
                <div class="row">
                    <div class="inputContainer col-12">
                        <label>Nova senha</label>
                        <Input
                            type="password"
                            placeholder="Digite a nova senha"
                            className="input-sidebar-minhaArea"
                            v-model="data.password"
                        />
                    </div>
                </div>
                
                <div class="row">
                    <div class="inputContainer col-12">
                        <label>Confirmar senha</label>
                        <Input
                            type="password"
                            placeholder="Confirme a nova senha"
                            className="input-sidebar-minhaArea"
                            v-model="data.confirm_password"
                        />
                    </div>
                </div>

                <Button type="sidebar-minhaArea" text="Cadastrar nova senha" :click="changePassword" />
            </form>
        </div>
    </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import Input from '../../Elements/Input.vue';
import Button from '../../Elements/Button.vue';
import { inject, ref } from 'vue';
import { PUT } from '../../../services/api';
import { decodeJwt } from '../../../services/util';
export default {
    name: "SidebarDadosTitular",
    props: {
        isMobile: Boolean
    },
    components: {
        Input,
        Button
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        const loader = inject("loading");
        const alert = inject("alert");
        const data = ref({
            password: "",
            confirm_password: ""
        });

        const closeSidebar = () => store.commit("setSidebarSenha", false);

        const changePassword = async () => {
            loader.open();
            if(data.value.password === data.value.confirm_password && data.value.password.length >= 6) {
                try{
                    const token = JSON.stringify(localStorage.getItem("token_comprodonto"));
                    const decoded = decodeJwt(token);
                    await PUT(`usuarios/${decoded.sub}`, {password: data.value.password});
                    alert.open("Sucesso", "Senha alterada com sucesso!");
                    closeSidebar();
                } catch (e) {
                    alert.open("Erro", 'Desculpe, não foi possível alterar sua senha, tente novamente mais tarde.');
                }
            } else if (data.value.password.length < 6) {
                alert.open("Atenção!", "A senha deve conter no mínimo 6 dígitos.");
            } else {
                alert.open("Atenção!", "As senhas não conferem.");
            }
            loader.close();
        };

        return { closeSidebar, store, router, data, changePassword }
    },
}
</script>

<style scoped>
    .sidebar-container{
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        width: 100%;
        height: 100vh;
    }

    .background{
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,.33);
        width: 100%;
        height: 100vh;
    }

    .content-container{
        position: fixed;
        top: 0;
        right: 0;
        background-color: #fff;
        width: 36rem;
        height: 100vh;
        padding: 2rem 4rem;
    }

    .sidebar-top{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .title{
        color: #c90c53;
        font-size: 1rem;
        font-weight: 500;
        line-height: .9375rem;
        text-transform: uppercase;
    }

    .iconClose{
        color: #C90C53;
        font-size: 2rem;
        user-select: none;
        cursor: pointer;
    }

    .inputContainer{
        position: relative;
        margin: 1.25rem 0;
    }

    .inputContainer label{
        position: absolute;
        top: -.5rem;
        left: 2rem;
        background-color: #fff;
        z-index: 10;
        padding: 0 .3rem;
        color: #4b4b4b;
        font-size: .75rem;
        font-weight: 500;
        line-height: .9675rem;
    }

    .inputContainer:hover > label{
        color: #ff96db;
    }

    .form-mobile{
        overflow-y: auto;
        height: 100vh;
        margin-top: 1rem;
        padding-bottom: 10rem;
        padding-right: .5rem;
    }

    @media screen and (max-width: 584px) {
        .content-container{
            width: 100%;
            padding: 2rem 1.5rem;
        }
    }
</style>