import router from '../router'
import { GET, POST } from "../services/api";
import store from '../../store/index';
import alert from './alert';
import loader from './loading';
import { numberToCpf, toDate } from './formater';
import * as shoppingCar from "./shoppingCar";

const decodeJwt = (jwt) => {
    const base64Url = jwt.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    
    return JSON.parse(jsonPayload);
}

const retornaLogin = (erro) => {
    if(erro.includes("Token")){
        router.push({ path: '/login' });
      }
}

const passwordStrength = (password) => {
    const scorePassword = (password) => {
        let score = 0;
        if (!password)
            return score;
        const letters = new Object();
        for (let i = 0; i < password.length; i++) {
            letters[password[i]] = (letters[password[i]] || 0) + 1;
            score += 5.0 / letters[password[i]];
        }
        const variations = {
            digits: /\d/.test(password),
            lower: /[a-z]/.test(password),
            upper: /[A-Z]/.test(password),
            nonWords: /\W/.test(password),
        }
        let variationCount = 0;
        for (const check in variations) {
            variationCount += (variations[check] == true) ? 1 : 0;
        }
        score += (variationCount - 1) * 10;
        return Number(score);
    }


    const score = scorePassword(password);
    if (score > 80)
        return "Forte";
    if (score > 60)
        return "Moderada";
    if (score > 30 || score <= 30)
        return "Fraca";

    return "";

}

const setCookie = (key, value, exdays) => {
    let date = new Date();
    date.setTime(date.getTime() + (exdays*24*60*60*1000));
    let expires = "expires=" + date.toUTCString();
    document.cookie = key + "=" + value + ";" + expires;
}

const setCookieWithDomain = (key, value, exdays, domain) => {
    let date = new Date();
    date.setTime(date.getTime() + (exdays*24*60*60*1000));
    let expires = "expires=" + date.toUTCString();
    document.cookie = key + "=" + value + ";domain=" + domain + ";" + expires;
}

const getCookie = (key) => {
    let name = key + "=";
    let pairs = document.cookie.split(';');
    for(let i = 0; i < pairs.length; i++) {
        let pair = pairs[i];
        while (pair.charAt(0) == ' ') pair = pair.substring(1);
        if (pair.indexOf(name) == 0) {
            return pair.substring(name.length, pair.length);
        }
    }
    return "";
}

function deleteCookie( name, path, domain ) {
    if( getCookie( name ) ) {
      document.cookie = name + "=" +
        ((path) ? ";path="+path:"")+
        ((domain)?";domain="+domain:"") +
        ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
    }
  }

function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }
    if (/android/i.test(userAgent)) {
        return "Android";
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "unknown";
}

const getDeviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return "tablet";
    }
    else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
        return "mobile";
    }
    return "desktop";
};

const createEventosSite = async (eventType, local) => {
    let id_lead = getCookie('lead_id_cookie');
    if(!id_lead){
        let obj = {
            nome: '',
            email: '',
            telefone: '',
            device: getDeviceType(),
            os: getMobileOperatingSystem()
        }
        const response = await POST("leads", obj);
        id_lead = response.id;
        let domain = process.env.NODE_ENV === 'production' ? ".comprodonto.com.br" : window.location.hostname;
        setCookieWithDomain('lead_id_cookie', id_lead, 14, domain);
        const objEvento = {
            acao: eventType,
            leads_id: Number(id_lead) || "",
            identificacao: local
        };
        await POST("eventos_sites", objEvento);
    } else {
        const objEvento = {
            acao: eventType,
            leads_id: Number(id_lead) || "",
            identificacao: local
        }
        await POST("eventos_sites", objEvento);
    }
};

const is18orMoreYearsOld = (day, month, year) => {
    return new Date(year+18, month-1, day) <= new Date();
};

const validatePhone = (phone)=>{
    console.log(phone)
    if (phone.length < 8) {
        return false; 
    } else{
        return true
    }
}

const registerDependentsWithHolder = async (hasHolder) => {
    loader.open();
    let objParam = {}
    let dependentes = store.getters.dependentes;
    let titular = store.getters.titular;
    if(hasHolder){
        objParam = {
            dependents: dependentes,
            titular: titular
        };
    } else{
        objParam = {
            dependents: dependentes,
            titular: null
        };
    }

    try {
        await POST("assinantes/dependentes", objParam);
        router.replace("/finalizar-compra/pagamento/");
        if (hasHolder) {
            titular.cpf = numberToCpf(titular.cpf); 
            titular.birth_date = toDate(titular.birth_date);
        }
        store.commit("setTitular", titular);
    } catch (e) {
        if (hasHolder) {
            titular.cpf = numberToCpf(titular.cpf); 
            titular.birth_date = toDate(titular.birth_date);
        }
        dependentes.map( dependente => {
            dependente.cpf = numberToCpf(dependente.cpf);
            // dependente.cns = dependente.cns.replace(/(\d{3})(\d{4})(\d{4})(\d{4})/, "$1 $2 $3 $4");
        });
        store.commit("setDependentes", dependentes);
        store.commit("setTitular", titular);
        alert.open("Erro!", "Desculpe, ocorreu um erro ao cadastrar os dependentes.");
    }
    loader.close();
};


const getCardType = (number) => {
    let re = new RegExp("^4");
    if (number.match(re) != null) return "visa";

    re = new RegExp("^(34|37)");
    if (number.match(re) != null) return "amex";

    re = new RegExp("^5[1-5]");
    if (number.match(re) != null) return "mastercard";

    re = new RegExp("^6011");
    if (number.match(re) != null) return "discover";

    re = new RegExp("^9792");
    if (number.match(re) != null) return "troy";

    return "visa"; // default type
};

const allPlansAreSelected = () => {
    const plans = {};
    const dependentes = {};
    store.getters.shoppingCar.plans.forEach(plan => {
        plans[plan.id] = (plans[plan.id] || 0) + plan.qtd_membros;
    });
    store.getters.dependentes.forEach(dependente => {
        if (dependente.plan_id) {
            dependentes[dependente.plan_id] = (dependentes[dependente.plan_id] || 0) + 1;
        }
    });
    for (const planId in plans) {
        if (plans[planId] !== dependentes[planId]) {
            return false;
        }
    }
    return true;
};

const setErrorsFormCadastro = (user, user_cadastro) => {
    user.name == "" 
        ? store.commit("setErrorsCadastro", {index: 0, value: true})
        : store.commit("setErrorsCadastro", {index: 0, value: false});
    user_cadastro.telefone == ""
        ? store.commit("setErrorsCadastro", {index: 1, value: true})
        : store.getters.user_cadastro.telefone.length < 14
        ? store.commit("setErrorsCadastro", {index: 1, value: true})
        : store.commit("setErrorsCadastro", {index: 1, value: false});
    user.email == "" 
        ? store.commit("setErrorsCadastro", {index: 2, value: true})
        : store.commit("setErrorsCadastro", {index: 2, value: false});
    user.cpf == "" 
        ? store.commit("setErrorsCadastro", {index: 3, value: true})
        : store.commit("setErrorsCadastro", {index: 3, value: false});
    user.password == "" 
        ? store.commit("setErrorsCadastro", {index: 4, value: true})
        : store.commit("setErrorsCadastro", {index: 4, value: false});
    user_cadastro.confirmaPassword == "" 
        ? store.commit("setErrorsCadastro", {index: 5, value: true})
        : store.commit("setErrorsCadastro", {index: 5, value: false});
    user.zip_code == "" 
        ? store.commit("setErrorsCadastro", {index: 6, value: true})
        : store.commit("setErrorsCadastro", {index: 6, value: false});
    user.district == "" 
        ? store.commit("setErrorsCadastro", {index: 7, value: true})
        : store.commit("setErrorsCadastro", {index: 7, value: false});
    user.street == "" 
        ? store.commit("setErrorsCadastro", {index: 8, value: true})
        : store.commit("setErrorsCadastro", {index: 8, value: false});
    user.street_number == "" 
        ? store.commit("setErrorsCadastro", {index: 9, value: true})
        : store.commit("setErrorsCadastro", {index: 9, value: false});
    user.birth_date == "" 
        ? store.commit("setErrorsCadastro", {index: 10, value: true})
        : store.commit("setErrorsCadastro", {index: 10, value: false});
};

const updateShoppingCar = async () => { 
    const token = JSON.parse(localStorage.getItem("token_comprodonto"));
    if (token) {
        const user_id = decodeJwt(token).usr.id;
        const carrinho = await GET(`/carrinho/${user_id}`);
        localStorage.setItem("planos", JSON.stringify(carrinho));
        shoppingCar.default.start();
    }
};

export { 
    decodeJwt, 
    passwordStrength, 
    retornaLogin, 
    setCookie, 
    setCookieWithDomain,
    getCookie, 
    deleteCookie,
    getMobileOperatingSystem,
    getDeviceType,
    createEventosSite, 
    is18orMoreYearsOld,
    registerDependentsWithHolder,
    getCardType,
    allPlansAreSelected,
    setErrorsFormCadastro,
    updateShoppingCar,
    validatePhone,
}